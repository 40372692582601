import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Sidebar from "../../../sidebar/Sidebar";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { server } from "../../../../server";
import axios from "axios";
import { Logout } from "@mui/icons-material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

const AddBorrower = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [images, setImages] = useState([]);
  const [guarantor_images, setGuarantorImages] = useState([]);
  const [guarantor_images2, setGuarantorImages2] = useState([]);
  const [creater, setCreater] = useState("");
  const [createrUserId, setCreaterUserId] = useState("");
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    idno: "",
    phone: "",
    email: "",
    createdBy: creater,
    createrId: createrUserId,
    guarantor_name: "",
    guarantor_phone: "",
    guarantor_idno: "",
    guarantor_name2: "",
    guarantor_phone2: "",
    guarantor_idno2: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setCreater(data?.firstname + " " + data?.secondname);
        setCreaterUserId(data?.userId);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const MAX_FILE_SIZE = 200 * 1024; // 200KB

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setImages((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        setLoading(false);
        return;
      }
      const firstname = formData.firstname;
      const lastname = formData.lastname;
      const idno = formData.idno;
      const phone = formData.phone;
      const email = formData.email;
      const createdBy = creater;
      const createrId = createrUserId;
      const guarantor_name = formData.guarantor_name;
      const guarantor_phone = formData.guarantor_phone;
      const guarantor_idno = formData.guarantor_idno;
      const guarantor_name2 = formData.guarantor_name2;
      const guarantor_phone2 = formData.guarantor_phone2;
      const guarantor_idno2 = formData.guarantor_idno2;

      const newForm = new FormData();

      images.forEach((image, index) => {
        newForm.append("images", image);
      });
      guarantor_images.forEach((guarantor_image, index) => {
        newForm.append("guarantor_images", guarantor_image);
      });
      guarantor_images2.forEach((guarantor_image2, index) => {
        newForm.append("guarantor_images2", guarantor_image2);
      });
      newForm.append("firstname", firstname);
      newForm.append("lastname", lastname);
      newForm.append("idno", idno);
      newForm.append("phone", phone);
      newForm.append("email", email);
      newForm.append("createdBy", createdBy);
      newForm.append("createrId", createrId);
      newForm.append("guarantor_name", guarantor_name);
      newForm.append("guarantor_phone", guarantor_phone);
      newForm.append("guarantor_idno", guarantor_idno);
      newForm.append("guarantor_name2", guarantor_name2);
      newForm.append("guarantor_phone2", guarantor_phone2);
      newForm.append("guarantor_idno2", guarantor_idno2);

      const response = await axios.post(
        `${server}/borrower/add-borrower`,
        newForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setLoading(false);
      toast.success("Borrower added successfully!");
      navigate("/borrowers");
    } catch (error) {
      setLoading(false);
      toast.error(`${error.response.data.error}`);
    }
    setLoading(false);
  };

  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };
  return (
    <div className="flex h-fit">
      <Sidebar />

      <div className="w-full h-[900px] border bg-white shadow-md rounded">
        <div className="w-full px-8 pt-6 pb-8 mb-4 bg-white rounded ">
          <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
            <div>
              <h3 className="text-lg font-medium leading-6 text-white">
                Add New Borrower
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-white">
                Register all the required fields.
              </p>
            </div>
            <div className="text-white">
              <button className="">
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>{" "}
              </button>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="mt-5 p-8 rounded border shadow-md border-t-4 border-t-red-500"
            encType="multipart/form-data"
          >
            <div class="grid gap-6 mb-6 md:grid-cols-2">
              <div>
                {" "}
                <label htmlFor="firstname">First Name: </label>
                <input
                  type="text"
                  className="block border border-grey-500 w-full p-3 rounded mb-4"
                  name="firstname"
                  placeholder="First Name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                {" "}
                <label htmlFor="lastname">Last Name: </label>
                <input
                  type="text"
                  className="block border border-grey-500 w-full p-3 rounded mb-4"
                  name="lastname"
                  placeholder="Last Name"
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                {" "}
                <label htmlFor="address">Id No: </label>
                <input
                  type="text"
                  className="block border border-grey-500 w-full p-3 rounded mb-4"
                  name="idno"
                  onChange={handleChange}
                  placeholder="Id No"
                  required
                />
              </div>
              <div>
                {" "}
                <label htmlFor="contactNumber">Contact Number: </label>
                <input
                  type="string"
                  className="block border border-grey-500 w-full p-3 rounded mb-4"
                  name="phone"
                  placeholder="Contact Number"
                  onChange={handleChange}
                  required
                />
              </div>
              <div>
                <label htmlFor="email">Email Address: </label>
                <input
                  type="email"
                  className="block border border-grey-500 w-full p-3 rounded mb-4"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                />
              </div>
            </div>
            {/* documents has started here */}
            Upload Borrower Documents
            <div className="flex">
              <div className="border rounded-md my-5 p-5">
                <label className="pb-2">Upload Borrower Documents</label>
                <input
                  type="file"
                  name="documents"
                  id="upload"
                  className="hidden"
                  multiple
                  onChange={handleImageChange}
                />
                <div className="w-full  mb-4 ml-3 flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <ControlPointDuplicateIcon
                      size={30}
                      className="mt-3"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((image, index) => (
                      <div className="relative" key={index}>
                        <img
                          src={image}
                          alt=""
                          className="h-[120px] w-[120px] object-cover m-2"
                        />
                        <p
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full cursor-pointer p-1"
                          onClick={() => deleteImage(index)}
                        >
                          <DeleteOutlineIcon size={16} />
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <button
              type="submit"
              disabled={loading}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/6"
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/6 ml-10">
              <Link to="/borrowers">Cancel</Link>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddBorrower;
