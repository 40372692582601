import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../../server";

const AddPayments = ({ loanId, balance, clientId }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [inputs, setInputs] = useState({
    amount: "",
    collection_date: new Date().toISOString().slice(0, 16),
    collected_by: user?.firstname || "",
    new_balance: "",
    method: "Mpesa",
    client_id: clientId,
    loan_id: loanId,
  });

  const onChange = (e) => {
    setInputs({ ...inputs, [e.target.name]: e.target.value });
  };

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    // Update collected_by when user data is available
    if (user) {
      setInputs((prevState) => ({
        ...prevState,
        collected_by: user.firstname,
      }));
    }
  }, [user]);

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }
      const response = await axios.post(
        `${server}/payments/add-payment/${clientId}/${loanId}`,
        inputs,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      toast.success("Payment added successfully!");
      navigate(`/Borrower/${clientId}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Adding payment failed", error);
      toast.error("Failed to add payment. Please try again.");
    }
  };

  useEffect(() => {
    const updatedBalance = balance - parseFloat(inputs.amount || 0);
    setInputs((prevState) => ({
      ...prevState,
      new_balance: updatedBalance,
    }));
  }, [inputs.amount, balance]);

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="flex mt-5 px-4 py-2 h-[530px] rounded border shadow-md border-t-4 border-t-red-500 ">
      {/* Add Loan */}
      <div className="w-full ">
        <div className="flex w-full items-center justify-between border-y-2 mt-5">
          <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
            Loan Payment
          </h3>
        </div>

        {/* FORM */}
        <form className="grid grid-cols-2 p-2 mt-2 " onSubmit={onSubmit}>
          <div className="flex w-full ">
            <div className="w-auto">
              <label htmlFor="client_id">Client ID:</label>
              <input
                type="number"
                className="block border border-grey-500 p-3 rounded mb-4 mr-5"
                name="client_id"
                value={clientId}
                disabled
              />
            </div>

            <div className="w-auto">
              <label htmlFor="loan_id">Voucher:</label>
              <input
                type="number"
                className="block border border-grey-500 p-3 rounded mb-4"
                placeholder="Voucher #"
                name="loan_id"
                value={loanId}
                disabled
                onChange={(e) => onChange(e)}
              />
            </div>
          </div>

          <div>
            <label htmlFor="collection_date">Collection Date:</label>
            <input
              type="datetime-local"
              className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
              placeholder="Collection Date"
              name="collection_date"
              value={inputs.collection_date}
              onChange={(e) => onChange(e)}
            />
          </div>

          <div>
            <label htmlFor="amount">Amount:</label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
              placeholder="Amount"
              name="amount"
              value={inputs.amount}
              onChange={(e) => onChange(e)}
            />
          </div>

          <div>
            <label htmlFor="collected_by">Collected By:</label>
            <input
              type="text"
              className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
              placeholder="Collected by"
              name="collected_by"
              value={inputs.collected_by}
              onChange={(e) => onChange(e)}
            />
          </div>

          <div>
            <label htmlFor="new_balance">New Balances:</label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
              name="new_balance"
              value={inputs.new_balance}
              onChange={(e) => onChange(e)}
            />
          </div>

          <div>
            <label htmlFor="terms">Method:</label>
            <select
              className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
              name="method"
              id="method"
              value={inputs.method}
              onChange={(e) => onChange(e)}
            >
              <option value="Mpesa">Mpesa</option>
              <option value="Airtel Money">Airtel Money</option>
              <option value="Cash">Cash</option>
              <option value="Bank">Bank</option>
            </select>
          </div>

          <div>
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-auto ml-auto "
              type="submit"
            >
              {loading ? "Adding..." : "Add Payment"}
            </button>
            <button
              onClick={goBack}
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/5 ml-10"
            >
              Back
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddPayments;
