import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LocationOnOutlined,
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Publish,
  Logout,
} from "@mui/icons-material";

import Sidebar from "../../sidebar/Sidebar";
import Admins from "./AllAdmins";
import { server } from "../../../server";
import { toast } from "react-toastify";
import Sellers from "./AllSellers";
import axios from "axios";

export default function AdminPage({ setAuth }) {
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      try {
        setLoading(true);
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };
  return (
    <div className="flex h-[1210px]">
      <Sidebar />
      <div className="w-full px-8 py-8 pb-8 mb-4 bg-white border rounded shadow-md h-fit">
        {/* HEADER */}
        <div className="px-4 py-5 sm:px-6 rounded shadow-md bg-red-500 flex justify-between items-center">
          <div>
            <h3 className="text-xl font-medium leading-6 text-white">
              Welcome, {user?.firstname}
            </h3>
            <span className="text-md font-medium leading-6 text-white">
              Logged in: {new Date().toLocaleTimeString()}
            </span>
          </div>

          <div>
            {/* LOGOUT BUTTON */}
            <div className="text-white float-right ">
              {/* ADMIN PAGE */}
              <button className="hover:-translate-y-0.5">
                <Link to="/admin">
                  <PermIdentity />
                </Link>
              </button>

              {/* LOGOUT */}
              <button className="ml-2 hover:-translate-y-0.5">
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>
              </button>
            </div>
            <span className="mr-10 text-lg font-medium leading-6 text-white">
              {new Date().toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              }) + ""}
            </span>
          </div>
        </div>
        <div className="flex gap-10">
          {/* ACCOUNT INFO */}
          <div className="w-1/4 h-[1030px] mt-5 border rounded shadow-md border-t-4 border-t-red-500">
            <div className="py-5 px-5 ">
              <h3 className="text-xl mb-5 border-b-2">Account Details</h3>
              <div className="flex flex-col justify-between items-center py-5 px-5 ">
                <img
                  src="https://cdn3.iconfinder.com/data/icons/red-icons-1/512/Male-profile-icon-512.png"
                  alt=""
                  className="w-full h-full"
                />
                {/* USER INFO */}
                <div className="">
                  <div>
                    <div className="flex items-center my-5">
                      <PermIdentity className="text-lg" />
                      <span className="ml-2.5">
                        {loading ? (
                          <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                        ) : (
                          <>
                            {user?.firstname} {user?.secondname}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center my-5">
                    <MailOutline className="text-lg " />
                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>{user?.email}</>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center my-5">
                    <PhoneAndroid className="text-lg " />
                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>{user?.phone}</>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center my-5"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="block">
            <Sellers />
            <Admins />
          </div>
        </div>
      </div>
    </div>
  );
}
