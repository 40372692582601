import React, { useState } from "react";
import axios from "axios";
import {
  ArrowBackIosNew,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { server } from "../../server";
import { toast } from "react-toastify";

const Register = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    firstname: "",
    secondname: "",
    phone: "",
    email: "",
    password: "",
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${server}/user/register`, formData);
      toast.success("Registration successful!");
      navigate("/login");
      setLoading(false);
    } catch (error) {
      setLoading(false);

      console.error("Registration failed", error.status);
      if (
        error?.response?.data?.error ===
        "Email already in use. Please use a different email."
      ) {
        toast.error("Email already in use. Please use a different email.");
      } else {
        toast.error(`${error?.response?.data?.error?.message}`);
      }
    }
    setLoading(false);
  };
  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex flex-col h-[750px] w-[620px] border rounded-md shadow-md mx-auto my-20 justify-start flex-wrap border-t-4 border-t-red-500">
      <div className="">
        <div className="flex justify-between items-center px-8 py-8">
          <div className="mt-5">
            <h1 className="text-xl font-semibold">
              Welcome to <span className="text-red-500">Lenders Bay</span>.
            </h1>
            <small className="text-gray-400">Please enter your details</small>
          </div>
          <div className="">
            <Link to="/">
              <ArrowBackIosNew />
            </Link>
          </div>
        </div>
        <form className="bg-white px-8 pb-8 mb-4" onSubmit={handleSubmit}>
          <div className="flex w-full gap-5">
            <div className="mb-4 w-full">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="firstname"
              >
                First Name:
              </label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="firstname"
                placeholder="Enter your first name"
                onChange={handleChange}
              />
            </div>
            <div className="mb-4 w-full">
              <label
                className="block text-gray-700 text-sm font-bold mb-2"
                htmlFor="secondname"
              >
                Second Name:
              </label>
              <input
                type="text"
                className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                name="secondname"
                placeholder="Enter your last name"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="phone"
            >
              Contact Number
            </label>
            <input
              type="string"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="phone"
              placeholder="Contact Number"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email:
            </label>
            <input
              type="email"
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="email"
              placeholder="Input your email address"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 relative">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              name="password"
              placeholder="*************"
              onChange={handleChange}
            />
            <div
              className="absolute inset-y-0 right-0 mt-6 flex items-center px-3 cursor-pointer"
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <button
            type="submit"
            className="w-full text-center py-3 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none my-1"
          >
            {loading ? "Creating..." : "Create Account"}
          </button>
          <div className="text-center">
            <span className="text-xs text-gray-400 font-semibold">
              Have an account?
            </span>
            <Link
              to="/login"
              className="text-xs font-semibold text-red-700 ml-2"
            >
              Sign in
            </Link>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
