import React, { useState } from "react";
import { useEffect } from "react";
import Sidebar from "../../../sidebar/Sidebar";
import { DeleteForever, Edit, Update, Logout } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { server } from "../../../../server";
import axios from "axios";
import Loader from "../../loader/Loader";

const Loans = ({ setAuth }) => {
  const [loans, setLoans] = useState([]);
  const [borrowers, setBorrowers] = useState({});
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setLoading(false);

        const data = await response.json();
        const sortedLoans = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoans(sortedLoans);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };
    const fetchBorrowers = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const sortedLoans = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoading2(false);

        // Create a map of borrower IDs to borrower objects
        const borrowerMap = {};
        sortedLoans.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setBorrowers(borrowerMap);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchBorrowers();

    fetchLoans();
  }, []);

  const deleteLoan = async (loanId) => {
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete this loan?"
      );
      if (userConfirmed) {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const loadingToastId = toast.info("Deleting Loan...", {
          autoClose: false,
        });
        await axios.delete(`${server}/loans/delete-loan`, {
          data: { loanId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.dismiss(loadingToastId);
        toast.success("Loan Deleted Successfully");
        setLoading(true);
        const response = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const sortedLoans = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoading(false);
        setLoans(sortedLoans);
      } else {
        console.log("Deletion cancelled by the user");
      }
    } catch (error) {
      console.error("Error deleting loan:", error);
    }
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex  h-[900px] ">
      <Sidebar />
      {/* <GetAllLoans /> */}
      <div className="flex w-full">
        {/* Loans Information */}
        <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded ">
          {/* HEADER */}
          <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
            {/* TITLE */}
            <div>
              <h3 className="text-lg font-medium leading-6 text-white">
                Loans Report
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-white">
                Loans summary and informations.
              </p>
            </div>

            {/* BUTTON */}

            <div className="text-white">
              <button className="">
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>
              </button>
            </div>
          </div>

          {/* TITLE */}
          <div className="flex items-center justify-between border-y-2 mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Loan Transactions
            </h3>
          </div>

          {/* ALL LOANS */}
          <div className="w-full h-[650px] px-4 overflow-auto hover:overflow-scroll mt-5 border rounded shadow-md border-t-4 border-t-red-500 ">
            {loading ? (
              <Loader />
            ) : (
              <table className="table-fixed text-center  ">
                <thead>
                  <tr>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Client Name
                    </th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">Loan Type</th>
                    <th className="w-1/6 px-1 py-2 text-gray-600">
                      Outstanding Balance
                    </th>
                    <th className="w-1/6 px-4 py-2 text-gray-600">
                      Gross Loan
                    </th>
                    <th className="w-1/6 px-4 py-2 text-gray-600">
                      Amortization
                    </th>
                    <th className="w-1/1 px-1 py-2 text-gray-600">
                      Grand Loan
                    </th>

                    <th className="w-1/6 px-4 py-2 text-gray-600">Terms</th>
                    <th className="w-1/6 px-4 py-2 text-gray-600">
                      Date Released
                    </th>
                    <th className="w-1/6 px-4 py-2 text-gray-600">Status</th>
                    <th className="w-1/1 px-4 py-2 text-gray-600">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.length <= 0 ? (
                    <tr className="border px-4 py-2 bg-red-50">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="px-4 py-2 bg-red-50">No Loan Data</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    loans.map((loan, index) => {
                      return (
                        <tr key={index}>
                          <td className="border px-4 py-2">
                            {loading2 ? (
                              <div className="">
                                <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                              </div>
                            ) : (
                              <>
                                {" "}
                                {borrowers[loan.borrower]?.firstname +
                                  " " +
                                  borrowers[loan.borrower]?.lastname}{" "}
                              </>
                            )}
                          </td>
                          <td className="border px-4 py-2  bg-gray-50">
                            {loan.type}
                          </td>
                          <td className="border px-4 py-2 ">
                            {" "}
                            {loan.balance.toLocaleString()}
                          </td>
                          <td className="border px-4 py-2  bg-gray-50">
                            {loan.gross_loan.toLocaleString()}
                          </td>
                          <td className="border px-4 py-2 ">
                            {" "}
                            {loan.amort.toLocaleString()}
                          </td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {loan.grand_amount?.toLocaleString()}
                          </td>
                          <td className="border px-4 py-2  bg-gray-50">
                            {loan.terms} week/s
                          </td>
                          <td className="border px-4 py-2 ">
                            {new Date(loan.date_released).toDateString()}
                          </td>
                          <td className="border px-4 py-2  bg-gray-50">
                            {/* <span className='border bg-green-600 text-white px-5 py-1 rounded-md'>
                            {loan.status}
                          </span> */}
                            {loan.balance === 0 ? (
                              <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                Fully Paid{" "}
                              </span>
                            ) : loan.status === "Approved" ||
                              loan.status === "Fully Paid" ||
                              loan.status === "Disbursed" ? (
                              <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                {loan.status}
                              </span>
                            ) : loan.status === "Declined" ? (
                              <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                {loan.status}
                              </span>
                            ) : (
                              <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                                {loan.status}
                              </span>
                            )}
                          </td>
                          <td className="border px-4 py-2">
                            <button
                              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                              onClick={() => deleteLoan(loan._id)}
                            >
                              <DeleteForever className="text-lg" />
                            </button>
                            <Link to={`/editLoan/${loan._id}`}>
                              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                <Edit className="text-sm" />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Loans;
