import { VisibilityOutlined } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { server } from "../../../../../server";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";

export default function ApprovalWidget() {
  const [loans, setLoans] = useState([]);
  const [borrowers, setBorrowers] = useState({});
  const [loading, setLoading] = useState([]);
  const [loading2, setLoading2] = useState([]);

  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLoading(false);
        setLoans(data);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    const fetchBorrowers = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const borrowerMap = {};
        data.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setBorrowers(borrowerMap);
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchBorrowers();
    fetchLoans();
  }, []);

  return (
    <div className="w-full h-[450px] ">
      {/* CLIENTS */}
      <div className=" mt-5 p-8 rounded-xl border border-t-4 border-t-red-500 cursor-pointer shadow-md">
        <h3 className="text-xl mb-5 border-b-2">Loans For Approval</h3>
        <div className="flex justify-between items-center">
          <div className="w-full h-[350px] overflow-auto hover:overflow-scroll">
            {loading ? (
              <Loader />
            ) : (
              <table className="table-fixed text-center w-full">
                <thead>
                  <tr className="">
                    <th className="w-1/1 px-1 py-2">Borrower</th>
                    <th className="w-1/1 px-1 py-2">Gross Loan</th>
                    <th className="w-1/1 px-1 py-2">Status</th>
                    <th className="w-1/6 px-1 py-2">View</th>
                  </tr>
                </thead>
                <tbody>
                  {loans.length <= 0 ? (
                    <tr className="border px-4 py-2 bg-red-50">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="px-4 py-2 bg-red-50">No Loan Data</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    loans.map((loan, index) => {
                      if (loan.status === "Pending")
                        return (
                          <tr key={index}>
                            <td className="border px-4 py-2 bg-gray-50">
                              {loading2 ? (
                                <div className="">
                                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                </div>
                              ) : (
                                <>{borrowers[loan.borrower]?.firstname}</>
                              )}
                            </td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {loan.gross_loan.toLocaleString()}
                            </td>
                            <td className="border text-sm  bg-gray-50">
                              {loan.balance === 0 ? (
                                <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                  Fully Paid{" "}
                                </span>
                              ) : loan.status === "Approved" ||
                                loan.status === "Fully Paid" ||
                                loan.status === "Disbursed" ? (
                                <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              ) : loan.status === "Declined" ? (
                                <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              ) : (
                                <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              )}
                            </td>
                            <td className="border px-4 py-2  bg-gray-50">
                              <Link to={`/editLoan/${loan._id}`}>
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                  <VisibilityOutlined className="text-sm" />
                                </button>
                              </Link>
                            </td>
                          </tr>
                        );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
