import React, { useEffect, useState } from "react";
import Sidebar from "../../../sidebar/Sidebar";
import GetBorrowers from "./GetBorrowers";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../../server";

export default function EmailPage({ setAuth }) {
  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };
  return (
    <div className="flex h-[900px]">
      <Sidebar />

      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* HEADER */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          {/* TITLE */}
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Send Email
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Send Emails to Our Borrowwers here.
            </p>
          </div>

          {/* BUTTON */}

          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>
        <GetBorrowers />
      </div>
    </div>
  );
}
