import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";

import Sidebar from "../../../sidebar/Sidebar";
import AddPayments from "./AddPayments";
import { server } from "../../../../server";
import { toast } from "react-toastify";
import axios from "axios";

const PaymentLoansInfo = ({ setAuth }) => {
  const [loans, setLoans] = useState([]);

  const location = useLocation();

  const clientId = location.pathname.split("/")[2];
  const loanId = location.pathname.split("/")[3];
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    const fetchLoanById = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(
          `${server}/loans/get-specific-loan/${loanId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        data.date_released = formatDate(data.date_released);
        data.maturity_date = formatDate(data.maturity_date);

        setLoans(data);
      } catch (error) {
        console.error("Error fetching loan:", error);
      }
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    };

    fetchLoanById();
  }, [loanId]);

  const navigate = useNavigate();
  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex h-[900px]">
      <Sidebar />

      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* HEADER */}

        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          {/* TITLE */}
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Payment for Loan Voucher #{loanId.replace(/\D/g, "")}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Add a payment for a client
            </p>
          </div>

          {/* BUTTON */}
          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>

        {/* Loans Information */}
        <div className="mt-5 px-4 h-[195px] rounded border shadow-md border-t-4 border-t-red-500 ">
          <div className="flex items-center justify-between border-y-2 mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Client's Loan
            </h3>
          </div>
          <table className="table-fixed text-center ">
            <thead>
              <tr>
                <th className="w-1/6 px-4 py-2 text-gray-600">Gross Loan</th>
                <th className="w-1/6 px-4 py-2 text-gray-600">
                  Outstanding Balance
                </th>
                <th className="w-1/6 px-4 py-2 text-gray-600">Amortization</th>
                <th className="w-1/1 px-1 py-2 text-gray-600">Grand Loan</th>
                <th className="w-1/6 px-4 py-2 text-gray-600">Terms</th>
                <th className="w-1/6 px-4 py-2 text-gray-600">Date Released</th>
                <th className="w-1/6 px-4 py-2 text-gray-600">Status</th>
              </tr>
            </thead>
            <tbody>
              {loans.length <= 0 ? (
                <tr className="border px-4 py-2 bg-red-50">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td className="px-4 py-2 bg-red-50">No Loan Data</td>
                  <td></td>
                  <td></td>
                  <td></td>
                </tr>
              ) : (
                <tr>
                  <td className="border px-4 py-2 ">
                    {" "}
                    {loans.gross_loan.toLocaleString()}
                  </td>
                  <td className="border px-4 py-2 ">
                    {" "}
                    {loans.balance.toLocaleString()}
                  </td>
                  <td className="border px-4 py-2 bg-gray-50">
                    {loans.amort.toLocaleString()}
                  </td>
                  <td className="border px-4 py-2 bg-gray-50">
                    {loans.grand_amount?.toLocaleString()}
                  </td>
                  <td className="border px-4 py-2 ">{loans.terms} week/s</td>
                  <td className="border px-4 py-2 bg-gray-50">
                    {new Date(loans.date_released).toDateString()}
                  </td>
                  <td className="border text-sm ">
                    {loans.status === "Approved" ||
                    loans.status === "Fully Paid" ? (
                      <span className=" bg-green-500 text-white px-4 py-1 rounded-md">
                        {loans.status}
                      </span>
                    ) : loans.status === "Declined" ? (
                      <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                        {loans.status}
                      </span>
                    ) : loans.status === "Pending" ? (
                      <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                        {loans.status}
                      </span>
                    ) : (
                      <span className=" bg-orange-300 text-white px-4 py-1 rounded-md">
                        {loans.status}
                      </span>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Payment Form */}
        <AddPayments
          loanId={loanId}
          balance={loans.balance}
          clientId={clientId}
        />
      </div>
    </div>
  );
};

export default PaymentLoansInfo;
