import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { toast } from "react-toastify";
import Sidebar from "../../../sidebar/Sidebar";
import axios from "axios";
import { server } from "../../../../server";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

const AddLoan = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();
  const [createrUserId, setCreaterUserId] = useState("");
  const [createrUserName, setCreaterUserName] = useState("");
  const [images, setImages] = useState([]);

  const [loanData, setLoanData] = useState({
    type: "Supper Quick",
    status: "Pending",
    gross_loan: 0,
    balance: 0,
    amort: 0,
    terms: 1,
    createrId: createrUserId,
    createrName: createrUserName,
    date_released: new Date().toISOString().slice(0, 16),
    maturity_date: "",
    grand_amount: 0,
    appraisal_fees: 0,
    excise_duty: 0,
    interest_rate: 0,
    interest_amount: 0,
    deposit: 0,
  });

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setCreaterUserId(data?.userId);
        setCreaterUserName(data?.firstname);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);
  const enforceLimits = () => {
    const { gross_loan, type, terms } = loanData;

    if (!gross_loan || gross_loan <= 0) {
      toast.error("Please enter a valid loan amount");
      return false;
    }

    let minGrossLoan = 0;
    let maxGrossLoan = 0;

    switch (type) {
      case "Pesa Pap":
        minGrossLoan = 0;
        maxGrossLoan = 50000;
        break;
      case "Supper Quick":
        minGrossLoan = 0;
        maxGrossLoan = 15000;
        break;
      case "LBC Pesa New":
        minGrossLoan = 16000;
        maxGrossLoan = 30000;
        break;
      case "LBC Pesa":
        minGrossLoan = 16000;
        maxGrossLoan = 30000;
        break;
      case "LBC Advantange Weekly":
        minGrossLoan = 31000;
        maxGrossLoan = 50000;
        break;
      case "LBC Advantange New":
        minGrossLoan = 31000;
        maxGrossLoan = 50000;
        break;
      case "LBC Express":
        minGrossLoan = 51000;
        maxGrossLoan = 150000;
        break;
      case "Working Capital":
        minGrossLoan = 151000;
        maxGrossLoan = 15000000;
        break;
      default:
        break;
    }

    if (gross_loan < minGrossLoan || gross_loan > maxGrossLoan) {
      toast.error(
        `Gross Loan must be between ${minGrossLoan} and ${maxGrossLoan} for selected loan type`
      );
      return false;
    }

    if (!terms || terms <= 0) {
      toast.error("Please select the loan terms");
      return false;
    }

    return true;
  };

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!enforceLimits()) {
      setLoading(false);
      return;
    }
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }
      const type = loanData.type;
      const status = loanData.status;
      const gross_loan = loanData.gross_loan;
      const balance = loanData.balance;
      const amort = loanData.amort;
      const terms = loanData.terms;
      const createrId = loanData.createrId;
      const createrName = loanData.createrName;
      const date_released = loanData.date_released;
      const maturity_date = loanData.maturity_date;
      const grand_amount = loanData.grand_amount;
      const appraisal_fees = loanData.appraisal_fees;
      const excise_duty = loanData.excise_duty;
      const interest_rate = loanData.interest_rate;
      const interest_amount = loanData.interest_amount;
      const deposit = loanData.deposit;

      const newForm = new FormData();

      images.forEach((image, index) => {
        newForm.append("images", image);
      });
      newForm.append("type", type);
      newForm.append("status", status);
      newForm.append("gross_loan", gross_loan);
      newForm.append("balance", balance);
      newForm.append("amort", amort);
      newForm.append("terms", terms);
      newForm.append("createrName", createrName);
      newForm.append("createrId", createrId);
      newForm.append("date_released", date_released);
      newForm.append("maturity_date", maturity_date);
      newForm.append("grand_amount", grand_amount);
      newForm.append("appraisal_fees", appraisal_fees);
      newForm.append("excise_duty", excise_duty);
      newForm.append("interest_rate", interest_rate);
      newForm.append("interest_amount", interest_amount);
      newForm.append("deposit", deposit);

      const response = await axios.post(
        `${server}/loans/add-loan/${id}`,
        newForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoading(false);
      navigate(`/borrower/${id}`);
      toast.success(response.data.message);
    } catch (error) {
      setLoading(false);
      console.error("Error adding loan:", error);
      toast.error("Failed to add loan. Please try again later.");
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const createrId = createrUserId;
    const createrName = createrUserName;

    let updatedLoanData = { ...loanData, [name]: value };

    if (name === "gross_loan") {
      const grossLoan = parseFloat(value);

      if (loanData.type === "Pesa Pap") {
        const appraisalFees = 0;
        const exciseDuty = 0;
        updatedLoanData = {
          ...updatedLoanData,
          appraisal_fees: appraisalFees?.toFixed(0),
          excise_duty: exciseDuty?.toFixed(0),
          createrId: createrId,
          createrName: createrName,
        };
      } else {
        const appraisalFees =
          grossLoan <= 15000
            ? 0
            : grossLoan < 51000
            ? 0.075 * grossLoan
            : 0.05 * grossLoan;
        const exciseDuty =
          grossLoan <= 15000
            ? 0
            : grossLoan < 51000
            ? 0.015 * grossLoan
            : 0.01 * grossLoan;

        updatedLoanData = {
          ...updatedLoanData,
          appraisal_fees: appraisalFees?.toFixed(0),
          excise_duty: exciseDuty?.toFixed(0),
          createrId: createrId,
          createrName: createrName,
        };
      }
    }
    if (name === "gross_loan" || name === "type") {
      const grossLoan = parseFloat(updatedLoanData.gross_loan);

      // Calculate interest rate based on loan type
      let interestRate = 0;

      switch (updatedLoanData.type) {
        case "Pesa Pap":
          interestRate = 0.025;
          break;
        case "Supper Quick":
          interestRate = 0.044117647;
          break;
        case "LBC Pesa New":
          interestRate = 0.01744186;
          break;
        case "LBC Pesa":
          interestRate = 0.014534883;
          break;
        case "LBC Advantange Weekly":
          interestRate = 0.007212209302;
          break;
        case "LBC Advantange New":
          interestRate = 0.008721899225;
          break;
        case "LBC Express":
          interestRate = 0.006369708995;
          break;
        case "Working Capital":
          interestRate = 0.004615384615;
          break;
        default:
          interestRate = 0;
      }

      updatedLoanData = {
        ...updatedLoanData,
        interest_rate: interestRate,
        createrId: createrId,
        createrName: createrName,
      };
      setLoanData(updatedLoanData);
    }
    if (name === "terms") {
      const terms = parseInt(value, 10);

      // Calculate interest rate based on loan type and number of terms
      let interestRate = 0;

      switch (updatedLoanData.type) {
        case "Pesa Pap":
          interestRate = 0.025;
          break;
        case "Supper Quick":
          interestRate = 0.044117647;
          break;
        case "LBC Pesa New":
          interestRate = 0.01744186;
          break;
        case "LBC Pesa":
          interestRate = 0.014534883;
          break;
        case "LBC Advantange Weekly":
          interestRate = 0.007212209302;
          break;
        case "LBC Advantange New":
          interestRate = 0.008721899225;
          break;
        case "LBC Express":
          interestRate = 0.006369708995;
          break;
        case "Working Capital":
          interestRate = 0.004615384615;
          break;
        default:
          interestRate = 0;
      }

      // Adjust interest rate based on the number of terms
      interestRate *= terms;

      updatedLoanData = {
        ...updatedLoanData,
        interest_rate: interestRate,
        createrId: createrId,
        createrName: createrName,
      };

      setLoanData(updatedLoanData);
    }
  };

  const handleChange2 = (e) => {
    setLoanData({
      ...loanData,
      [e.target.name]: e.target.value,
    });
  };
  // const totalAmount =
  //   parseFloat(loanData.gross_loan) +
  //   parseFloat(loanData.appraisal_fees) +
  //   parseFloat(loanData.excise_duty);

  const totalAmount =
    parseFloat(loanData.gross_loan) + parseFloat(loanData.appraisal_fees);

  useEffect(() => {
    const interestAmount = totalAmount * loanData.interest_rate;
    setLoanData((prevLoanData) => ({
      ...prevLoanData,
      interest_amount: interestAmount?.toFixed(0),
    }));
  }, [loanData.interest_rate, totalAmount]);

  useEffect(() => {
    const grantTotals =
      parseFloat(totalAmount) + parseFloat(loanData.interest_amount);
    const real_amortization = grantTotals / loanData.terms;

    setLoanData((prevLoanData) => ({
      ...prevLoanData,
      grand_amount: grantTotals?.toFixed(0),
      balance: grantTotals?.toFixed(0),
      amort: real_amortization.toFixed(0),
    }));
  }, [loanData.interest_amount, totalAmount]);

  useEffect(() => {
    const currentDate = new Date();
    const maturityDate = new Date(currentDate);
    maturityDate.setDate(currentDate.getDate() + loanData.terms * 7);

    setLoanData((prevLoanData) => ({
      ...prevLoanData,
      maturity_date: maturityDate.toISOString().slice(0, 10),
    }));
  }, [loanData.terms]);

  const MAX_FILE_SIZE = 200 * 1024; // 200KB

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setImages((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };
  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };
  const deleteImage = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  return (
    <div className="flex h-[900px]">
      <Sidebar />

      {/* Add Loan */}
      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* TITLE */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Add Loan for Client #{id?.replace(/\D/g, "")}
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              *Fill all the required fields (and chronological)
            </p>
          </div>

          {/* BUTTON */}
          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>

        {/* FORM */}
        <form
          className="grid grid-cols-2 mt-5 p-8 h-[650px] rounded border shadow-md border-t-4 border-t-red-500 "
          onSubmit={handleSubmit}
        >
          {/* TYPE */}
          <div>
            <label htmlFor="type">Type of Loan:</label>
            <select
              className="block border border-grey-500 w-10/12 p-3 rounded"
              name="type"
              id="type"
              onChange={handleChange}
              value={loanData.type}
            >
              <option value="Pesa Pap">Pesa Pap - short term (4 wks)</option>
              <option value="Supper Quick">
                Supper Quick (4 wks) {`=>`} (0 - 15,000)
              </option>
              <option value="LBC Pesa">
                LBC Pesa (8wks) {`=>`} (16,000 - 30,000)
              </option>
              <option value="LBC Pesa New">
                LBC Pesa New (12 wks) {`=>`} (16,000 - 30,000)
              </option>
              <option value="LBC Advantange New">
                LBC Advantange New (24 wks) {`=>`} (31,000 - 50,000)
              </option>
              <option value="LBC Advantange Weekly">
                LBC Advantange Weekly (24 wks) {`=>`} (31,000 - 50,000)
              </option>
              <option value="LBC Express">
                LBC Express (24) {`=>`} (51,000 - 150,000)
              </option>
              <option value="Working Capital">
                Working Capital {`=>`} (150,000 and above)
              </option>
            </select>
          </div>

          {/* STATUS */}
          <div>
            <label htmlFor="status">Status:</label>
            <input
              className="block border border-grey-500 w-10/12 p-3 rounded"
              name="status"
              id="status"
              value="Pending"
              disabled
            />
          </div>

          {/* GROSS LOAN */}
          <div>
            <label htmlFor="gross_loan">Loan Amount:</label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded"
              placeholder="Gross Loan"
              name="gross_loan"
              onChange={handleChange}
              value={loanData.gross_loan}
            />
          </div>

          {/* BALANCE */}
          <div>
            <label htmlFor="balance">Balance:</label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded"
              placeholder="Balance"
              name="balance"
              onChange={handleChange}
              value={loanData.grand_amount}
            />
          </div>
          {/* TERMS */}
          <div>
            <label htmlFor="terms">Terms:</label>
            <select
              className="block border border-grey-500 w-10/12 p-3 rounded "
              name="terms"
              id="terms"
              onChange={handleChange}
              value={loanData.terms}
            >
              <option value="1">1 Week</option>
              <option value="2">2 Weeks</option>
              <option value="3">3 Weeks</option>
              <option value="4">4 Weeks</option>
              <option value="5">5 Weeks</option>
              <option value="6">6 Weeks</option>
              <option value="7">7 Weeks</option>
              <option value="8">8 Weeks</option>
              <option value="9">9 Weeks</option>
              <option value="10">10 Weeks</option>
              <option value="11">11 Weeks</option>
              <option value="12">12 Weeks</option>
              <option value="13">13 Week</option>
              <option value="14">14 Weeks</option>
              <option value="15">15 Weeks</option>
              <option value="16">16 Weeks</option>
              <option value="17">17 Weeks</option>
              <option value="18">18 Weeks</option>
              <option value="19">19 Weeks</option>
              <option value="20">20 Weeks</option>
              <option value="21">21 Weeks</option>
              <option value="22">22 Weeks</option>
              <option value="23">23 Weeks</option>
              <option value="24">24 Weeks</option>
              <option value="25">25 Weeks</option>
              <option value="26">26 Weeks</option>
              <option value="27">27 Weeks</option>
              <option value="28">28 Weeks</option>
              <option value="29">29 Weeks</option>
              <option value="30">30 Weeks</option>
              <option value="31">31 Weeks</option>
              <option value="32">32 Weeks</option>
              <option value="33">33 Week</option>
              <option value="34">34 Weeks</option>
              <option value="35">35 Weeks</option>
              <option value="36">36 Weeks</option>
              <option value="37">37 Weeks</option>
              <option value="38">38 Weeks</option>
              <option value="39">39 Weeks</option>
              <option value="40">40 Weeks</option>
              <option value="41">41 Weeks</option>
              <option value="42">42 Weeks</option>
              <option value="43">43 Week</option>
              <option value="44">44 Weeks</option>
              <option value="45">45 Weeks</option>
              <option value="46">46 Weeks</option>
              <option value="47">47 Weeks</option>
              <option value="48">48 Weeks</option>
              <option value="49">49 Weeks</option>
              <option value="50">50 Weeks</option>
              <option value="51">51 Weeks</option>
              <option value="52">52 Weeks</option>
            </select>
          </div>

          {/* AMORTIZATION */}
          <div>
            <label htmlFor="amort">Amortization (per week):</label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded "
              placeholder="Amortization"
              name="amort"
              onChange={handleChange}
              value={loanData.amort}
            />
          </div>

          {/* DATE RELEASED */}
          <div>
            <label htmlFor="date_released">Date Released:</label>
            <input
              type="datetime-local"
              className="block border border-grey-500 w-10/12 p-3 rounded "
              placeholder="Date Released"
              name="date_released"
              onChange={handleChange}
              value={loanData.date_released}
            />
          </div>

          {/* MATURITY DATE */}
          <div>
            <label htmlFor="maturity_date">Maturity Date:</label>
            <input
              type="date"
              className="block border border-grey-500 w-10/12 p-3 rounded "
              placeholder="Maturity Date"
              name="maturity_date"
              onChange={handleChange}
              value={loanData.maturity_date}
            />
          </div>

          <div class="w-10/12 mb-3 mr-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
            <ul
              class="flex flex-wrap text-sm font-medium text-center text-gray-500 border-b border-gray-200 rounded-t-lg bg-gray-50 dark:border-gray-700 dark:text-gray-400 dark:bg-gray-800"
              id="defaultTab"
              data-tabs-toggle="#defaultTabContent"
              role="tablist"
            >
              <li class="me-2">
                <button
                  id="about-tab"
                  data-tabs-target="#about"
                  type="button"
                  role="tab"
                  aria-controls="about"
                  aria-selected="true"
                  class="inline-block p-4 rounded-ss-lg  dark:bg-gray-800 dark:hover:bg-gray-700"
                >
                  Loan summary
                </button>
              </li>
            </ul>
            <div className="ml-5 flex">
              <div>
                <p className="text-lg font-semibold mb-2">
                  Actual Loan: Ksh.{" "}
                  {loanData.gross_loan
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
                <p className="text-gray-600 mb-2">
                  Appraisal Fees: Ksh.{" "}
                  {loanData.appraisal_fees
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
                <p className="text-gray-600 mb-2">
                  Excise Duty: Ksh.{" "}
                  {loanData.excise_duty
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
                <p className="text-lg mb-2 font-semibold text-red-500">
                  Gross Loan: Ksh.{" "}
                  {totalAmount
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
              </div>
              <div className="ml-10 mt-8">
                <p className="text-gray-600 mb-2">
                  Interest Rate:{" "}
                  {(loanData.interest_rate * 100).toFixed(2) || 0}%
                </p>
                <p className="text-gray-600 mb-2">
                  Interest Amount: Ksh.{" "}
                  {loanData.interest_amount
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
                <p className="text-lg font-semibold text-red-500">
                  Grant Totals:{" "}
                  {loanData.grand_amount
                    ?.toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || 0}
                </p>
              </div>
            </div>
          </div>
          <div className="ml-3">
            <div className="flex">
              <div className="border rounded-md my-5 p-5">
                <label className="pb-2">Upload Loan Form</label>
                <input
                  type="file"
                  name="documents"
                  id="upload"
                  className="hidden"
                  multiple
                  onChange={handleImageChange}
                />
                <div className="w-full  mb-4 ml-3 flex items-center flex-wrap">
                  <label htmlFor="upload">
                    <ControlPointDuplicateIcon
                      size={30}
                      className="mt-3"
                      color="#555"
                    />
                  </label>
                  {images &&
                    images.map((image, index) => (
                      <div className="relative" key={index}>
                        <img
                          src={image}
                          alt=""
                          className="h-[120px] w-[120px] object-cover m-2"
                        />
                        <p
                          className="absolute top-2 right-2 bg-red-500 text-white rounded-full cursor-pointer p-1"
                          onClick={() => deleteImage(index)}
                        >
                          <DeleteOutlineIcon size={16} />
                        </p>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>

          {/* initial investment */}
          <div>
            <label htmlFor="deposit"> Initial Investment: </label>
            <input
              type="number"
              className="block border border-grey-500 w-10/12 p-3 rounded "
              placeholder="Initial Investment"
              name="deposit"
              onChange={handleChange2}
              // value={loanData.deposit}
            />
          </div>

          {/* BUTTONS */}
          <div className="ml-4">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-auto ml-auto "
              type="submit"
            >
              {loading ? "Adding..." : "Add New Loan"}
            </button>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/5 ml-10">
              <Link to={`/borrower/${id}`}>Cancel</Link>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddLoan;
