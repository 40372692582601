import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { DeleteForever } from "@mui/icons-material";
import { toast } from "react-toastify";
import axios from "axios";
import { server } from "../../../../server";
import Loader from "../../loader/Loader";

const PaymentsInfo = ({ fetchUpdatedLoans }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await axios.get(
          `${server}/payments/get-user-payments/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedPayments = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setPayments(sortedPayments);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching payments:", error);
      }
    };

    fetchPayments();
  }, [id]);

  const deletePayment = async (paymentId) => {
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete this payment?"
      );

      if (userConfirmed) {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const loadingToastId = toast.info("Deleting Loan...", {
          autoClose: false,
        });

        await axios.delete(`${server}/payments/delete-payment`, {
          data: { paymentId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.dismiss(loadingToastId);

        setLoading(true);

        const response = await axios.get(
          `${server}/payments/get-user-payments/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const sortedPayments = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setPayments(sortedPayments);
        setLoading(false);

        toast.success("Payment Deleted Successfully");
        // window.location.reload();
        fetchUpdatedLoans();
      } else {
        console.log("Deletion cancelled by the user");
      }
    } catch (error) {
      console.error("Error deleting loan:", error);
      // Handle error, show toast or perform other actions as needed
      toast.error("Error deleting payment");
    }
  };

  return (
    <div className="h-[350px] overflow-hidden hover:overflow-scroll border rounded shadow-md px-8 py-8 border-t-4 border-t-red-500">
      {/* Payment History */}
      <div className="flex items-center justify-between border-y-2 ">
        <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
          Payment History
        </h3>
      </div>
      {loading ? (
        <div className="table-fixed">
          <Loader />
        </div>
      ) : (
        <table className="table-fixed text-center ">
          <thead>
            <tr>
              <th className="w-1/1 px-1 py-2 text-gray-600">Voucher</th>
              <th className="w-1/5 px-1 py-2 text-gray-600">Amount</th>
              <th className="w-1/5 px-4 py-2 text-gray-600">Collection Date</th>
              <th className="w-1/5 px-1 py-2 text-gray-600">New Balance</th>
              <th className="w-1/5 px-4 py-2 text-gray-600">Collected By:</th>
              <th className="w-1/5 px-4 py-2 text-gray-600">Method</th>
              <th className="w-1/5 px-4 py-2 text-gray-600">Delete</th>
            </tr>
          </thead>
          <tbody>
            {payments.length <= 0 ? (
              <tr className="border px-4 py-2 bg-red-50">
                <td></td>
                <td></td>
                <td></td>
                <td className="px-4 py-2 bg-red-50">No Payment Data</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ) : (
              payments.map((payment, index) => {
                return (
                  <tr key={index}>
                    <td className="border px-4 py-2 bg-gray-50">
                      {payment.loan_id.replace(/\D/g, "")}
                    </td>
                    <td className="border px-4 py-2 ">
                      {" "}
                      {payment.amount?.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {new Date(payment.collection_date).toDateString()}
                    </td>
                    <td className="border px-4 py-2">
                      {" "}
                      {payment.new_balance?.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {payment.collected_by}
                    </td>
                    <td className="border px-4 py-2 ">
                      {payment.method === "ATM" ? (
                        <span className=" bg-green-500 text-white px-4 py-1 rounded-md">
                          {payment.method}
                        </span>
                      ) : payment.method === "OTC" ? (
                        <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                          {payment.method}
                        </span>
                      ) : payment.method === "ONLINE BANK" ? (
                        <span className=" bg-orange-300 text-white px-4 py-1 rounded-md">
                          {payment.method}
                        </span>
                      ) : (
                        <span className=" bg-blue-500 text-white px-4 py-1 rounded-md">
                          {payment.method}
                        </span>
                      )}
                    </td>
                    <td className="border px-4 py-2 ">
                      {" "}
                      <button
                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-3 rounded focus:outline-none focus:shadow-outline  text-sm"
                        onClick={() => deletePayment(payment._id)}
                      >
                        <DeleteForever className="text-lg" />
                      </button>
                    </td>
                  </tr>
                );
              })
            )}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default PaymentsInfo;
