import React, { useState } from "react";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Check, VisibilityOutlined } from "@mui/icons-material";
import Message from "./Message";
import { server } from "../../../../server";
import { toast } from "react-toastify";
import Loader from "../../loader/Loader";

const GetBorrowers = ({ setAuth }) => {
  const [clients, setClients] = useState([]);
  const [emails, setEmail] = useState("");
  const [names, setNames] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchBorrowers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setClients(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchBorrowers();
  }, []);

  // Select EMAIL CLIENT Function
  async function selectClient(email, firstname) {
    try {
      setEmail(clients.map((client) => email));
      setNames(clients.map((client) => firstname));
    } catch (error) {
      console.log(error.message);
    }
  }

  return (
    <div className="text-gray-900">
      {/* EMAIL PAGE ITEMS */}
      <div className="flex gap-5">
        {/* ALL CLIENT CONTACT INFO */}
        <div className="w-1/2">
          {/* TITLE */}
          <div className="flex items-center justify-between border-y-2 mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Clients Contact Info
            </h3>
          </div>
          {/* INFO */}
          <div className="w-full h-[650px] px-4 overflow-auto hover:overflow-scroll mt-5 border rounded shadow-md border-t-4 border-t-red-500 ">
            {loading ? (
              <Loader />
            ) : (
              <table className="table-fixed text-center">
                <thead className="">
                  <tr className="">
                    <th className="w-1/4 px-1 py-2 text-gray-600">Full Name</th>
                    <th className="w-1/4 px-1 py-2 text-gray-600">
                      Contact Number
                    </th>
                    <th className="w-1/4 px-1 py-2 text-gray-600">Email</th>
                    <th className="w-1/1 px-1 py-2 text-gray-600">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.length <= 0 ? (
                    <tr className="border px-4 py-2 bg-red-50">
                      <td></td>
                      <td></td>
                      <td className="px-4 py-2 bg-red-50">No Client Data</td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    clients.map((client, index) => {
                      return (
                        <tr key={index}>
                          <td className="border px-4 py-2 ">
                            {client.firstname + " " + client.lastname}{" "}
                          </td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client.phone}
                          </td>
                          <td className="border px-4 py-2 bg-gray-50">
                            {client.email}
                          </td>
                          <td className="border px-4 py-2">
                            <div className="flex">
                              <Link
                                to={`/Borrower/${client._id}`}
                                className="mr-2"
                              >
                                <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full mr-2 ">
                                  <VisibilityOutlined className="text-sm" />
                                </button>
                              </Link>
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full "
                                onClick={() =>
                                  selectClient(client.email, client.firstname)
                                }
                              >
                                <Check />
                              </button>
                            </div>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
        {/* EMAIL FORM */}
        <div className="w-1/2">
          {/* TITLE */}
          <div className="flex items-center justify-between border-y-2 mt-5">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Email Form
            </h3>
          </div>
          <Message email={emails[0]} names={names[0]} />
        </div>
      </div>
    </div>
  );
};

export default GetBorrowers;
