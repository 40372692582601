import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { toast, ToastContainer } from "react-toastify";
import Sidebar from "../../../sidebar/Sidebar";
import OneLoan from "./OneLoan";
import { server } from "../../../../server";
import Gloader from "../../loader/Gloader";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";

const EditLoan = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [images, setImages] = useState([]);
  const [currentImages, setcurrentImages] = useState([]);

  const { id } = useParams();
  const [borrowers, setBorrowers] = useState({});
  const navigate = useNavigate();
  const [loanData, setLoanData] = useState({
    type: "",
    status: "",
    gross_loan: 0,
    balance: 0,
    amort: 0,
    terms: "",
    date_released: "",
    maturity_date: "",
    deposit: 0,
  });
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);
  useEffect(() => {
    const fetchLoanById = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(
          `${server}/loans/get-specific-loan/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        data.date_released = formatDate(data.date_released);
        data.maturity_date = formatDate(data.maturity_date);
        setcurrentImages(data.documents);
        setLoading2(false);

        setLoanData(data);
      } catch (error) {
        setLoading2(false);

        console.error("Error fetching loan:", error);
      }
    };
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const formattedDate = date.toISOString().split("T")[0];
      return formattedDate;
    };

    const fetchBorrowers = async () => {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const borrowerMap = {};
        data.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setBorrowers(borrowerMap);
      } catch (error) {
        console.error("Error fetching borrowers:", error);
      }
    };
    fetchBorrowers();
    fetchLoanById();
  }, [id]);

  const onChange = (e) => {
    setLoanData({
      ...loanData,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }

      const type = loanData.type;
      const status = loanData.status;
      const gross_loan = loanData.gross_loan;
      const balance = loanData.balance;
      const amort = loanData.amort;
      const terms = loanData.terms;
      const deposit = loanData.deposit;
      const date_released = loanData.date_released;
      const maturity_date = loanData.maturity_date;

      const newForm = new FormData();

      images.forEach((image, index) => {
        newForm.append("images", image);
      });
      newForm.append("type", type);
      newForm.append("status", status);
      newForm.append("gross_loan", gross_loan);
      newForm.append("balance", balance);
      newForm.append("amort", amort);
      newForm.append("terms", terms);
      newForm.append("deposit", deposit);
      newForm.append("date_released", date_released);
      newForm.append("maturity_date", maturity_date);

      const response = await axios.put(
        `${server}/loans/update-loan/${id}`,
        newForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // const data = await response.json();

      // if (response.ok) {
      toast.success("Loan updated successfully");
      navigate(`/Borrower/${loanData.borrower}`);
      // } else {
      //   console.error("Failed to update loan:", data); // Log the actual error response
      //   toast.error(data.error || "Failed to update loan");
      // }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error updating loan:", error);
      toast.error("Internal Server Error");
    }
    setLoading(false);
  };

  const MAX_FILE_SIZE = 200 * 1024; // 200KB

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setImages((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange2 = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setcurrentImages((prevImages) => [...prevImages, ...files]);
  };

  const deleteImage = async (index, image) => {
    const loadingToastId = toast.info("Deleting Image...", {
      autoClose: false,
    });
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        setLoading(false);
        return;
      }
      await axios.delete(`${server}/loans/delete-image/${id}`, {
        data: { image },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss(loadingToastId);
      const updatedImages = [...currentImages];
      updatedImages.splice(index, 1); // Remove the image at the specified index
      setcurrentImages(updatedImages);
      toast.success("Image deleted!");
    } catch (error) {
      console.log(error);
      toast.dismiss(loadingToastId);
      toast.error(error.response.data.error);
    }
  };
  const deleteImage2 = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };

  const setOperations = async (index, image) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (isConfirmed) {
      // setIndex(index);
      deleteImage(index, image);
    }
  };
  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex h-fit">
      <Sidebar />

      <div className="w-full  h-fit mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* HEADER */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          {/* TITLE */}
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Update Loan Voucher #{id.replace(/\D/g, "")} (
              {borrowers[loanData.borrower]?.firstname}{" "}
              {borrowers[loanData.borrower]?.lastname})
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Edit and update your loan
            </p>
          </div>

          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>

        {/* LOAN INFO */}
        <OneLoan />

        {/* EDIT FORM */}
        <div className="mt-5 px-4 py-2 h-fit rounded border shadow-md border-t-4 border-t-red-500 ">
          <h3 className="text-lg font-medium leading-6 text-gray my-2 px-1 py-4 border-y-2 ">
            Edit Form
          </h3>
          {loading2 ? (
            <div class="flex justify-center items-center h-[50%]">
              <div class="rounded-full h-10 w-10 bg-red-600 animate-ping"></div>
            </div>
          ) : (
            <form onSubmit={onSubmit} className="grid grid-cols-2 p-2">
              {/* LOAN TYPE */}
              <div>
                <label htmlFor="type">Loan Type:</label>
                <select
                  className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
                  name="type"
                  id="type"
                  value={loanData.type}
                  onChange={onChange}
                >
                  <option value="Supper Quick">
                    Supper Quick {`=>`} (0 - 15,000)
                  </option>
                  <option value="LBC Pesa">
                    LBC Pesa {`=>`} (16,000 - 30,000)
                  </option>
                  <option value="LBC Advantange">
                    LBC Advantange {`=>`} (31,000 - 50,000)
                  </option>
                  <option value="LBC Express">
                    LBC Express {`=>`} (51,000 - 150,000)
                  </option>
                  <option value="Working Capital">
                    Working Capital {`=>`} (150,000 and above)
                  </option>
                </select>
              </div>

              {/* LOAN STATUS */}
              <div>
                <label htmlFor="status">Status:</label>
                <select
                  className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
                  name="status"
                  id="status"
                  value={loanData.status}
                  onChange={onChange}
                >
                  <option value="Approved">Approved</option>
                  <option value="Fully Paid">Fully Paid</option>
                  <option value="Disbursed">Disbursed</option>
                  <option value="Pending">Pending</option>
                  <option value="Declined">Declined</option>
                </select>
              </div>

              {/* GROSS LOAN */}
              <div>
                <label htmlFor="gross_loan">Gross Loan:</label>
                <input
                  type="number"
                  className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
                  name="gross_loan"
                  value={loanData.gross_loan}
                  onChange={onChange}
                  placeholder="Gross Loan"
                />
              </div>

              {/* BALANCE */}
              <div>
                <label htmlFor="balance">Balance:</label>
                <input
                  type="number"
                  className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
                  name="balance"
                  value={loanData.balance}
                  onChange={onChange}
                  placeholder="Balance"
                />
              </div>

              {/* AMORTIZATION */}
              <div>
                <label htmlFor="amort">Amortization:</label>
                <input
                  type="number"
                  className="block border border-grey-500t w-10/12 p-3 rounded mb-4"
                  name="amort"
                  value={loanData.amort}
                  onChange={onChange}
                  placeholder="Monthly Amortization"
                />
              </div>

              {/* TERMS */}
              <div>
                <label htmlFor="terms">Terms:</label>
                <select
                  className="block border border-grey-500 w-10/12 p-3 rounded mb-4"
                  name="terms"
                  id="terms"
                  value={loanData.terms}
                  onChange={onChange}
                >
                  <option value="1">1 Week</option>
                  <option value="2">2 Weeks</option>
                  <option value="3">3 Weeks</option>
                  <option value="4">4 Weeks</option>
                  <option value="5">5 Weeks</option>
                  <option value="6">6 Weeks</option>
                  <option value="7">7 Weeks</option>
                  <option value="8">8 Weeks</option>
                  <option value="9">9 Weeks</option>
                  <option value="10">10 Weeks</option>
                  <option value="11">11 Weeks</option>
                  <option value="12">12 Weeks</option>
                  <option value="13">13 Week</option>
                  <option value="14">14 Weeks</option>
                  <option value="15">15 Weeks</option>
                  <option value="16">16 Weeks</option>
                  <option value="17">17 Weeks</option>
                  <option value="18">18 Weeks</option>
                  <option value="19">19 Weeks</option>
                  <option value="20">20 Weeks</option>
                  <option value="21">21 Weeks</option>
                  <option value="22">22 Weeks</option>
                  <option value="23">23 Weeks</option>
                  <option value="24">24 Weeks</option>
                </select>
              </div>

              {/* DATE RELEASED */}
              <div>
                <label htmlFor="date_released">Date Released:</label>
                <input
                  type="date"
                  className="block border border-grey-500t w-10/12 p-3 rounded mb-4"
                  name="date_released"
                  value={loanData.date_released}
                  onChange={onChange}
                  placeholder="Date Released"
                />
              </div>

              {/* MATURITY DATE */}
              <div>
                <label htmlFor="maturity_date">Maturity Date:</label>
                <input
                  type="date"
                  className="block border border-grey-500t w-10/12 p-3 rounded mb-4"
                  name="maturity_date"
                  value={loanData.maturity_date}
                  onChange={onChange}
                  placeholder="Maturity Date"
                />
              </div>
              <div>
                <label htmlFor="date_released">Initial Investment:</label>
                <input
                  type="number"
                  className="block border border-grey-500t w-10/12 p-3 rounded mb-4"
                  name="deposit"
                  placeholder="Initial Inv."
                  value={loanData.deposit}
                  onChange={onChange}
                />
              </div>
              {/* images */}
              <div class="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <label className="pb-2">Current Images</label>
                  <input
                    type="text"
                    name=""
                    id=""
                    className="hidden"
                    multiple
                    onChange={handleImageChange2}
                  />
                  <div className="w-full flex items-center">
                    <label htmlFor="upload"></label>
                    {currentImages &&
                      currentImages.map((image, index) => (
                        <>
                          <div
                            className="border rounded-md mr-2 cursor-pointer"
                            key={index}
                          >
                            <img
                              src={`${image.url}`}
                              alt=""
                              className="h-[120px] w-[120px] object-cover m-2"
                            />
                            <div
                              className="text-red-500 cursor-pointer"
                              onClick={() => setOperations(index, image)}
                            >
                              <DeleteOutlineIcon size={20} />
                            </div>
                          </div>
                        </>
                      ))}
                  </div>
                  <br />
                </div>
                <div className="ml-7">
                  <label className="pb-2">
                    Upload Images <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="file"
                    name="documents"
                    id="upload"
                    className="hidden"
                    multiple
                    onChange={handleImageChange}
                  />
                  <div className="w-full flex items-center flex-wrap">
                    <label htmlFor="upload" className="mr-5">
                      <ControlPointDuplicateIcon
                        size={30}
                        className="mt-3"
                        color="#555"
                      />
                    </label>
                    {images &&
                      images?.map((image, index) => (
                        <div
                          className="border rounded-md mr-2 cursor-pointer"
                          key={index}
                        >
                          <img
                            src={image}
                            alt=""
                            className="h-[120px] w-[120px] object-cover m-2"
                          />
                          <div
                            className="text-red-500 cursor-pointer"
                            onClick={() => deleteImage2(index, image)}
                          >
                            <DeleteOutlineIcon size={20} />
                          </div>
                        </div>
                      ))}
                  </div>
                  <br />
                </div>
              </div>

              {/* BUTTONS */}
              <div>
                <button
                  type="submit"
                  className="text-center py-3 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none my-1 w-1/5"
                >
                  {loading ? "Updating..." : "Update"}
                </button>
                <Link
                  className="ml-5 text-center py-3 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none my-1 w-1/5 inline-block"
                  to={`/Borrower/${loanData.borrower}`}
                >
                  Back
                </Link>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default EditLoan;
