import axios from "axios";
import React, { useEffect, useState } from "react";
import { server } from "../../../../server";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import PushPinIcon from "@mui/icons-material/PushPin";

const Comment = ({ setReload }) => {
  const [loading, setLoading] = useState(false);
  const [borrower, setBorrower] = useState(null);
  const [commentText, setCommentText] = useState("");

  const { id } = useParams();
  const [user, setUser] = useState();

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response.json();
        setUser(data);
        const response2 = await fetch(`${server}/borrower/get-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data2 = await response2.json();
        data2.comments.sort(
          (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
        );

        setBorrower(data2);
      } catch (error) {
        console.error("Error fetching user:", error);
      }
    };
    fetchBorrowerByToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }
      const response = await axios.post(
        `${server}/borrower/comment/${id}`,
        {
          commenterName: user?.firstname,
          commentText: commentText,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCommentText("");
      toast.success("Comment added!");
      setLoading(false);
      const response2 = await fetch(`${server}/borrower/get-borrower/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data2 = await response2.json();
      data2.comments.sort(
        (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
      );
      setBorrower(data2);
    } catch (error) {
      console.error("Error Occurred", error);
      if (
        error?.response?.data?.error ===
        "Email already in use. Please use a different email."
      ) {
        toast.error("Email already in use. Please use a different email.");
      } else {
        toast.error(`${error?.response?.data?.error?.message}`);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const formatCommentDate = (commentDate) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return new Date(commentDate).toLocaleDateString("en-US", options);
  };

  const handleDeleteComment = async (commentId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this comment?."
    );
    if (isConfirmed) {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await axios.delete(
          `${server}/borrower/delete-comment/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
            data: { commentId: commentId },
          }
        );
        const response2 = await fetch(`${server}/borrower/get-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data2 = await response2.json();
        data2.comments.sort(
          (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
        );
        setBorrower(data2);
        setReload(true);
        toast.success("Comment deleted successfully!");
      } catch (error) {
        console.error("Error deleting comment:", error.response.data.error);
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to delete comment.");
      } finally {
        setLoading(false);
      }
    }
  };
  const handlePinComment = async (commentId) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to pin this comment?."
    );
    if (isConfirmed) {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        console.log("cm id", commentId);
        const response = await axios.put(
          `${server}/borrower/pin-comment/${id}`,
          { commentId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const response2 = await fetch(`${server}/borrower/get-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data2 = await response2.json();
        data2.comments.sort(
          (a, b) => new Date(b.commentDate) - new Date(a.commentDate)
        );
        setBorrower(data2);
        toast.success("Comment Pinned successfully!");
        setReload(true);
      } catch (error) {
        console.error("Error deleting comment:", error.response.data.error);
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to pin comment.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <section class="bg-white dark:bg-gray-900  antialiased">
        <div class="max-w-2xl ml-[28%] px-4">
          <div className="h-[700px] overflow-hidden hover:overflow-scroll border rounded shadow-md px-8 py-8 border-t-4 border-t-red-500">
            <div className="flex items-center justify-between border-y-2">
              <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
                Borrower's Comments ({borrower?.comments.length})
              </h3>
            </div>
            <form class="mb-6 mt-3" onSubmit={handleSubmit}>
              <div class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                <label for="comment" class="sr-only">
                  Your comment
                </label>
                <textarea
                  id="comment"
                  rows="6"
                  class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                  placeholder="Write a comment..."
                  onChange={(e) => setCommentText(e.target.value)}
                  name="commentText"
                  required
                ></textarea>
              </div>
              <button
                type="submit"
                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-auto mt-2"
              >
                {loading ? "Posting..." : "Post comment"}
              </button>
            </form>
            {borrower?.comments.map((comment, index) => (
              <>
                <article class="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
                  <footer class="flex justify-between items-center mb-2">
                    <div class="flex items-center">
                      <p class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                        <img
                          class="mr-2 w-6 h-6 rounded-full"
                          src="https://cdn3.iconfinder.com/data/icons/red-icons-1/512/Male-profile-icon-512.png"
                          alt="Michael Gough"
                        />
                        {comment.commenterName}{" "}
                      </p>
                      <p class="text-sm text-gray-600 dark:text-gray-400">
                        <time
                          pubdate
                          datetime="2022-02-08"
                          title="February 8th, 2022"
                        >
                          {formatCommentDate(comment.commentDate)}
                        </time>
                      </p>
                    </div>
                    <div className="flex">
                      <div
                        className="pointer mt-[2px]"
                        onClick={() => handlePinComment(comment._id)}
                        style={{
                          cursor: "pointer",
                          color:
                            comment.status === "pinned" ? `green` : "black",
                        }}
                      >
                        <PushPinIcon />
                      </div>
                      <div
                        className="pointer"
                        onClick={() => handleDeleteComment(comment._id)}
                        style={{ cursor: "pointer", color: "red" }}
                      >
                        <DeleteForeverIcon />
                      </div>
                    </div>
                  </footer>
                  <p class="text-gray-500 dark:text-gray-400">
                    {comment.commentText}
                  </p>
                </article>
              </>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Comment;
