import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import {
  MailOutline,
  PermIdentity,
  PhoneAndroid,
  Logout,
} from "@mui/icons-material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CreditCardIcon from "@mui/icons-material/CreditCard";

import Sidebar from "../../../sidebar/Sidebar";
import LoanInfo from "../loans/Loan";
import { server } from "../../../../server";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Comment from "./Comment";
import axios from "axios";
import Alert from "@mui/material/Alert";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export default function Borrower({ setAuth }) {
  const { id } = useParams();
  const [borrower, setBorrower] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState(null);

  const [user, setUser] = useState();

  const email = user?.email;

  const pinnedComment = borrower?.comments.find(
    (comment) => comment.status === "pinned"
  );

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    const fetchBorrowerById = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        if (response.ok) {
          setBorrower(data);
          setError(null);
        } else {
          setError(data.error || "Failed to fetch borrower");
        }
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrower:", error);
        setError("Internal Server Error");
      }
    };

    fetchBorrowerById();
  }, [id, reload]);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="h-fit flex">
      <Sidebar />

      <div className="w-full h-fit mx-auto px-4 py-4 mb-4 border bg-white shadow-md rounded ">
        {/* BORROWER INFO */}
        {/* HEADER */}
        <div className="flex items-center justify-between px-3 py-3 sm:px-6 bg-red-500 rounded shadow-md ">
          {/* TITLE */}
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Borrower Info
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              All client's information
            </p>
          </div>

          {/* BUTTON */}

          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>
        {pinnedComment && (
          <Alert
            icon={<ErrorOutlineIcon fontSize="inherit" />}
            severity="error"
            className="mt-2"
          >
            {pinnedComment.commentText}
          </Alert>
        )}

        {/* BORROWER ITEMS */}
        <div className="flex">
          {/* ACCOUNT INFO */}
          <div className="w-1/4 h-fit mt-5 border rounded shadow-md border-t-4 border-t-red-500">
            <div className="py-5 px-5 ">
              <div className="flex flex-col justify-between items-center py-5 px-5 ">
                <img
                  src="https://cdn3.iconfinder.com/data/icons/red-icons-1/512/Male-profile-icon-512.png"
                  alt=""
                  className="w-10 h-10"
                />
                {/* USER INFO */}
                <div className="">
                  <div className="flex items-center my-5">
                    <PermIdentity className="text-lg" />
                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>
                          {borrower?.firstname} {borrower?.lastname}{" "}
                        </>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center my-5">
                    <MailOutline className="text-lg " />

                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>{borrower?.email}</>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center my-5">
                    <PhoneAndroid className="text-lg " />
                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>{borrower?.phone}</>
                      )}
                    </span>
                  </div>
                  <div className="flex items-center my-5">
                    <CreditCardIcon className="text-lg " />
                    <span className="ml-2.5">
                      {loading ? (
                        <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                      ) : (
                        <>{borrower?.idno}</>
                      )}
                    </span>
                  </div>
                  {borrower?.deposit > 0 ? (
                    <div className="flex items-center my-5">
                      <AttachMoneyIcon className="text-lg " />
                      <span className="ml-2.5">
                        {loading ? (
                          <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                        ) : (
                          <>
                            Kshs.{" "}
                            {borrower?.deposit
                              ?.toString()
                              .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                          </>
                        )}
                      </span>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="flex gap-x-1">
                  {borrower?.documents?.map((document, index) => (
                    <div key={index} className="">
                      <a
                        href={document.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={document.url}
                          alt={`Document ${index + 1}`}
                          className="w-full h-full object-cover cursor-pointer"
                        />
                      </a>
                    </div>
                  ))}
                </div>
                <div>
                  {borrower?.guarantor_idno ? (
                    <>
                      <div className="flex items-center text-red-400 my-5">
                        <p>Guarantor One Details</p>
                      </div>
                      <div className="flex items-center my-5">
                        <PermIdentity className="text-lg " />
                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_name}</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center my-5">
                        <PhoneAndroid className="text-lg " />

                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_phone}</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center my-5">
                        <CreditCardIcon className="text-lg " />
                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_idno}</>
                          )}{" "}
                        </span>
                      </div>
                      <div className="flex gap-x-1">
                        {borrower?.documents_guarantor?.map(
                          (document, index) => (
                            <div key={index} className="">
                              <a
                                href={document.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={document.url}
                                  alt={`Document ${index + 1}`}
                                  className="w-full h-full object-cover cursor-pointer"
                                />
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center my-5">
                      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                        <Link to={`/add-guarantor/${id}`}>Add Guarantor 1</Link>
                      </button>
                    </div>
                  )}

                  {/* guarantor 2 starts here */}
                  {borrower?.guarantor_idno2 ? (
                    <>
                      {" "}
                      <div className="flex items-center text-red-400 my-5">
                        <p>Guarantor Two Details</p>
                      </div>
                      <div className="flex items-center my-5">
                        <PermIdentity className="text-lg " />
                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_name2}</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center my-5">
                        <PhoneAndroid className="text-lg " />

                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_phone2}</>
                          )}
                        </span>
                      </div>
                      <div className="flex items-center my-5">
                        <CreditCardIcon className="text-lg " />
                        <span className="ml-2.5">
                          {loading ? (
                            <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-20"></div>
                          ) : (
                            <>{borrower?.guarantor_idno2}</>
                          )}{" "}
                        </span>
                      </div>
                      <div className="flex gap-x-1">
                        {borrower?.documents_guarantor2?.map(
                          (document, index) => (
                            <div key={index} className="">
                              <a
                                href={document.url}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <img
                                  src={document.url}
                                  alt={`Document ${index + 1}`}
                                  className="w-full h-full object-cover cursor-pointer"
                                />
                              </a>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="flex items-center my-5">
                      <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                        <Link to={`/add-guarantor/${id}`}>Add Guarantor 2</Link>
                      </button>
                    </div>
                  )}

                  <div className="flex items-center my-5">
                    <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                      <Link to={`/editBorrower/${id}`}>UPDATE CLIENT</Link>
                    </button>
                  </div>
                  <span class="bg-green-100 text-green-800 text-xs font-medium me-2 px-2.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300">
                    created by:{" "}
                    {borrower?.createdBy ? borrower?.createdBy : "Admin"}
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* LOAN INFO */}
          <div className="w-4/5">
            <LoanInfo />
          </div>
        </div>
        <div className="w-4/5">
          <Comment setReload={setReload} />
        </div>
      </div>
    </div>
  );
}
