import { CreditScore } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import { server } from "../../../../../server";
import { toast } from "react-toastify";

export default function LoansWidget() {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(false);

  const grossLoanTotal = loans
    .map((loan) => loan.grand_amount)
    .reduce((total, amount) => total + amount, 0);

  const LoanBalanceTotal = loans
    ?.map((loan) => loan.gross_loan)
    .reduce((total, amount) => total + amount, 0);

  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setLoans(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchLoans();
  }, []);

  return (
    <div className="w-full">
      {/* Loans */}
      <div
        className="w-full  mt-5 p-8 rounded-xl cursor-pointer border border-t-4 border-t-red-500 hover:bg-red-500
        hover:text-white hover:text-base transition duration-150
        ease-in-out shadow-md"
      >
        <span className="text-xl">Loans</span>
        <div className="my-1">
          <span className="text-xl">
            <div className="flex">
              <CreditScore className="mr-3" />
              <div>
                {" "}
                <div className="flex">
                  <div>Gross: Shs. </div>{" "}
                  {loading ? (
                    <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                  ) : (
                    <>{grossLoanTotal.toLocaleString()}</>
                  )}{" "}
                </div>
              </div>
            </div>
          </span>
          <span className="text-xl">
            <div className="flex">
              <CreditScore className="mr-3" />
              <div>Net: Shs.</div>
              <div className="flex">
                {loading ? (
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                ) : (
                  <> {LoanBalanceTotal.toLocaleString()}</>
                )}
              </div>
            </div>
          </span>
        </div>
        <span className="text-base text-gray-500">
          Total Loans Transactions
        </span>
      </div>
    </div>
  );
}
