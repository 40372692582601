import { AddBox, PermIdentity } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import { server } from "../../../../../server";
import { toast } from "react-toastify";

export default function ClientsWidget() {
  const [clients, setClients] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);

  useEffect(() => {
    const fetchBorrowers = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setClients(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };
    const fetchAdmins = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/user/`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLoading2(false);
        setAdmins(data);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchAdmins();
    fetchBorrowers();
  }, []);

  return (
    <div className="w-full">
      {/* CLIENTS */}
      <div
        className="mt-5 p-8 rounded-xl border border-t-4 border-t-red-500 cursor-pointer hover:bg-red-500
        hover:text-white hover:text-base transition duration-150
        ease-in-out shadow-md"
      >
        <div className="flex justify-between items-center">
          <span className="text-xl">Borrowers</span>
        </div>

        <div className="my-1">
          <span className="text-xl flex">
            <PermIdentity className="mr-3" />
            <div className="flex">
              <div>Borrowers:</div>
              <div>
                {loading ? (
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                ) : (
                  <>{clients?.length}</>
                )}
              </div>
            </div>
          </span>
          <span className="text-xl flex">
            <PermIdentity className="mr-3" />
            <div className="flex">
              <div>Admins:</div>
              <div>
                {loading ? (
                  <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
                ) : (
                  <>{admins?.users?.length}</>
                )}
              </div>
            </div>
          </span>
        </div>
        <span className="text-base text-gray-500">Total Clients Serviced</span>
      </div>
    </div>
  );
}
