import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { DeleteForever, Edit } from "@mui/icons-material";
import axios from "axios";
import PaymentsInfo from "../payments/ListPayments";
import { server } from "../../../../server";
import AmortizationSchedule from "./AmortizationSchedule";
import Loader from "../../loader/Loader";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

const LoanInfo = () => {
  const [loans, setLoans] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [commentText, setCommentText] = useState("");
  const [user, setUser] = useState();

  const { id } = useParams();

  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true);

      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response1 = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });
        const data = await response1.json();
        setUser(data);
        const response = await axios.get(`${server}/loans/get-loan/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedLoans = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoans(sortedLoans);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching loans:", error);
        setError("Failed to fetch loans");
      }
    };

    fetchLoans();
  }, [id]);

  const deleteLoan = async (loanId) => {
    const loadingToastId = toast.info("Deleting Loan...", {
      autoClose: false,
    });
    try {
      const userConfirmed = window.confirm(
        "Are you sure you want to delete this loan? All Payments Associated with this Loan will be deleted as well!!"
      );
      if (userConfirmed) {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        await axios.delete(`${server}/loans/delete-loan`, {
          data: { loanId },
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        toast.dismiss(loadingToastId);
        setLoading(true);
        const response = await axios.get(`${server}/loans/get-loan/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const sortedLoans = response.data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoading(false);

        setLoans(sortedLoans);
        toast.success("Loan Deleted Successfully");
      } else {
        console.log("Deletion cancelled by the user");
      }
    } catch (error) {
      toast.dismiss(loadingToastId);
      console.error("Error deleting loan:", error);

      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Internal Server Error");
      }
    }
  };

  const fetchUpdatedLoans = async () => {
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }

      const response = await axios.get(`${server}/loans/get-loan/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const sortedLoans = response.data.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      });
      setLoans(sortedLoans);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching loans:", error);
      setError("Failed to fetch loans");
    }
  };

  const handleSubmit = async (e, id) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }
      const response = await axios.post(
        `${server}/loans/comment`,
        {
          commenterName: user?.firstname,
          commentText: commentText,
          id: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setCommentText("");
      toast.success("Comment added!");
      fetchUpdatedLoans();
      setLoading(false);
    } catch (error) {
      console.error("Error Occurred", error);
      if (
        error?.response?.data?.error ===
        "Email already in use. Please use a different email."
      ) {
        toast.error("Email already in use. Please use a different email.");
      } else {
        toast.error(`${error?.response?.data?.error?.message}`);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handleDeleteComment = async (commentId, id) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this comment?."
    );
    if (isConfirmed) {
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await axios.delete(`${server}/loans/delete-comment`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: { commentId: commentId, id: id },
        });
        fetchUpdatedLoans();
        toast.success("Comment deleted successfully!");
      } catch (error) {
        console.error("Error deleting comment:", error.response.data.error);
        if (error.response.data.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to delete comment.");
      } finally {
        setLoading(false);
      }
    }
  };

  const formatCommentDate = (commentDate) => {
    const options = { month: "short", day: "numeric", year: "numeric" };
    return new Date(commentDate).toLocaleDateString("en-US", options);
  };
  return (
    <div className="">
      {/* Loans Information */}
      <div className="flex flex-col w-full mx-auto pl-8 py-4 mb-4 bg-white gap-5 ">
        {/* Active Loans */}
        <div className="h-[700px] overflow-hidden hover:overflow-scroll border rounded shadow-md px-8 py-8 border-t-4 border-t-red-500">
          <div className="flex items-center justify-between border-y-2">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Loan Transactions
            </h3>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-auto mt-2">
              <Link to={`/addLoan/${id}`}>Add Loan</Link>
            </button>
          </div>
          <>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <>
                <table className="table-fixed">
                  <thead>
                    <tr>
                      <th className="w-1/6 px-1 py-2 text-gray-600">
                        Loan Type
                      </th>
                      <th className="w-1/6 px-1 py-2 text-gray-600">
                        Outstanding Balance
                      </th>
                      <th className="w-1/6 px-4 py-2 text-gray-600">
                        Gross Loan
                      </th>
                      <th className="w-1/6 px-4 py-2 text-gray-600">
                        Amortization
                      </th>
                      <th className="w-1/1 px-1 py-2 text-gray-600">
                        Grand Loan
                      </th>
                      <th className="w-1/6 px-4 py-2 text-gray-600">Terms</th>
                      <th className="w-1/6 px-4 py-2 text-gray-600">
                        Date Released
                      </th>
                      <th className="w-1/6 px-4 py-2 text-gray-600">Status</th>
                      <th className="w-1/1 px-4 py-2 text-gray-600">Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loans.length <= 0 ? (
                      <tr className="border px-4 py-2 bg-red-50">
                        <td></td>
                        <td></td>
                        <td></td>
                        <td>No</td>
                        <td className="px-4 py-2 bg-red-50"> Loan </td>
                        <td>Data</td>
                        <td></td>
                        <td></td>
                        <td></td>
                      </tr>
                    ) : (
                      loans.map((loan, index) => (
                        <>
                          <tr key={index}>
                            <td className="border px-4 py-2">{loan.type}</td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {loan.balance?.toLocaleString()}
                            </td>
                            <td className="border px-4 py-2 ">
                              {loan.gross_loan?.toLocaleString()}
                            </td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {loan.amort?.toLocaleString()}
                            </td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {loan.grand_amount?.toLocaleString()}
                            </td>
                            <td className="border px-4 py-2 ">
                              {loan.terms} week/s
                            </td>
                            <td className="border px-4 py-2 bg-gray-50">
                              {new Date(loan.date_released).toDateString()}
                            </td>
                            <td className="border text-sm">
                              {loan.balance === 0 ? (
                                <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                  Fully Paid{" "}
                                </span>
                              ) : loan.status === "Approved" ||
                                loan.status === "Fully Paid" ? (
                                <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              ) : loan.status === "Declined" ? (
                                <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              ) : loan.status === "Pending" ? (
                                <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              ) : (
                                <span className=" bg-orange-300 text-white px-4 py-1 rounded-md">
                                  {loan.status}
                                </span>
                              )}
                            </td>

                            <td className="border px-4 py-2 flex">
                              <button
                                className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-3 mb-2 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                                onClick={() => deleteLoan(loan._id)}
                              >
                                <DeleteForever className="text-lg" />
                              </button>
                              <button className="bg-red-500 hover:bg-red-700 text-white px-3 rounded focus:outline-none focus:shadow-outline h-10 ml-2 mr-2">
                                <Link to={`/editLoan/${loan._id}`}>
                                  <Edit className="text-sm" />
                                </Link>
                              </button>
                              <button className="bg-red-500 hover:bg-red-700 text-white font-bold h-10 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                <Link to={`/payment/${id}/${loan._id}`}>
                                  sh
                                </Link>
                              </button>
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="9" className="text-center">
                              <AmortizationSchedule loanDetails={loan} />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="9" className="text-center">
                              {/* <AmortizationSchedule loanDetails={loan} /> */}
                              <div className="mb-4">
                                <h4 className="text-lg font-medium leading-6 text-gray my-2 px-1 py-2">
                                  Loan Form Images
                                </h4>
                                <div className="flex justify-center gap-x-1">
                                  {loan?.documents?.map((document, index) => (
                                    <div key={index} className="">
                                      <a
                                        href={document.url}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <img
                                          src={document.url}
                                          alt={`Document ${index + 1}`}
                                          className="w-full h-full object-cover cursor-pointer"
                                        />
                                      </a>
                                    </div>
                                  ))}
                                </div>
                              </div>{" "}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="9">
                              <h4 className="text-lg font-medium leading-6 text-gray my-2 px-1 py-2 text-center">
                                Loan's Comments
                              </h4>
                              {loan?.comments.map((comment, index) => (
                                <>
                                  <div class="p-6 text-base bg-white rounded-lg dark:bg-gray-900">
                                    <footer class="flex justify-between items-center mb-2">
                                      <div class="flex items-center">
                                        <p class="inline-flex items-center mr-3 text-sm text-gray-900 dark:text-white font-semibold">
                                          <img
                                            class="mr-2 w-6 h-6 rounded-full"
                                            src="https://cdn3.iconfinder.com/data/icons/red-icons-1/512/Male-profile-icon-512.png"
                                            alt="Michael Gough"
                                          />
                                          {comment.commenterName}{" "}
                                        </p>
                                        <p class="text-sm text-gray-600 dark:text-gray-400">
                                          <time
                                            pubdate
                                            datetime="2022-02-08"
                                            title="February 8th, 2022"
                                          >
                                            {formatCommentDate(
                                              comment.commentDate
                                            )}
                                          </time>
                                        </p>
                                      </div>
                                      <div
                                        className="pointer"
                                        onClick={() =>
                                          handleDeleteComment(
                                            comment._id,
                                            loan._id
                                          )
                                        }
                                        style={{
                                          cursor: "pointer",
                                          color: "red",
                                        }}
                                      >
                                        <DeleteForeverIcon />
                                      </div>
                                    </footer>
                                    <div class="text-gray-500 dark:text-gray-400">
                                      {comment.commentText}
                                    </div>
                                  </div>
                                </>
                              ))}
                              <form
                                class="mb-6 mt-3"
                                onSubmit={(e) => handleSubmit(e, loan._id)}
                              >
                                <div class="py-2 px-4 mb-4 bg-white rounded-lg rounded-t-lg border border-gray-200 dark:bg-gray-800 dark:border-gray-700">
                                  <label for="comment" class="sr-only">
                                    Your comment
                                  </label>
                                  <textarea
                                    id="comment"
                                    rows="4"
                                    class="px-0 w-full text-sm text-gray-900 border-0 focus:ring-0 focus:outline-none dark:text-white dark:placeholder-gray-400 dark:bg-gray-800"
                                    placeholder="Write a comment..."
                                    onChange={(e) =>
                                      setCommentText(e.target.value)
                                    }
                                    name="commentText"
                                    required
                                  ></textarea>
                                </div>
                                <button
                                  type="submit"
                                  className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-auto mt-2"
                                >
                                  {loading ? "Posting..." : "Post comment"}
                                </button>
                              </form>
                              <hr
                                style={{
                                  display: "block",
                                  height: "1px",
                                  border: 0,
                                  borderTop: "1px solid #ccc",
                                  margin: "2px 0",
                                  padding: 0,
                                }}
                              />
                              <hr
                                style={{
                                  display: "block",
                                  height: "1px",
                                  border: 0,
                                  borderTop: "1px solid #ccc",
                                  padding: 0,
                                }}
                              />{" "}
                            </td>
                          </tr>
                        </>
                      ))
                    )}
                  </tbody>
                </table>
              </>
            )}
          </>
        </div>
        <div></div>
        {/* Payment History */}
        <PaymentsInfo fetchUpdatedLoans={fetchUpdatedLoans} />
      </div>
    </div>
  );
};

export default LoanInfo;
