import React from "react";
import { Link, useParams } from "react-router-dom";
import moment from "moment"; // Import the moment library for date manipulation

const AmortizationSchedule = ({ loanDetails }) => {
  const { id } = useParams();

  const {
    balance,
    interest_rate,
    grand_amount,
    amort,
    date_released,
    gross_loan,
    maturity_date,
    terms,
    deposit,
    _id,
  } = loanDetails;

  const calculateAmortizationData = () => {
    const monthlyInterestRate = interest_rate / 12 / 100;
    const numberOfPayments = terms;

    let remainingBalance = grand_amount;
    const amortizationData = [];

    let currentDate = moment(date_released).add(7, "days");
    let totalAmortization = 0;

    amortizationData.push({
      date: moment(date_released).format("YYYY-MM-DD"),
      principalPayment: 0,
      principalPayment2: 0,
      interestPayment: 0,
      amount: 0,
      weeklyAmort: 0,
      balance: remainingBalance,
      status: "Released",
      overUnderPayment: 0, //under overp
    });

    for (let month = 1; month <= numberOfPayments; month++) {
      const interestPayment = remainingBalance * monthlyInterestRate;
      const principalPayment = Math.round(amort);
      const principalPayment2 = Math.round(gross_loan / terms);
      const amount = amort;

      remainingBalance -= principalPayment;
      const weeklyAmort = amount - principalPayment2;
      totalAmortization += amount;
      const paymentsUptoHere = grand_amount - balance;

      const rowData = {
        date: currentDate.format("YYYY-MM-DD"),
        principalPayment,
        principalPayment2,
        interestPayment,
        amount,
        weeklyAmort,
        balance: remainingBalance,
        overUnderPayment:
          balance === 0
            ? 0
            : remainingBalance < balance
            ? totalAmortization - paymentsUptoHere
            : 0,

        status:
          balance === 0
            ? "Paid"
            : remainingBalance < balance
            ? "Unpaid"
            : "Paid",
      };

      amortizationData.push(rowData);
      currentDate.add(7, "days");
    }

    return amortizationData;
  };

  const amortizationData = calculateAmortizationData();

  return (
    <div className="mb-4">
      <h4 className="text-lg font-medium leading-6 text-gray my-2 px-1 py-2">
        Amortization Schedule{" "}
        {deposit > 0 ? (
          <p className="text-sm">Deposit: {deposit}</p>
        ) : (
          <p className="text-sm">Deposit: {deposit}</p>
        )}
      </h4>
      <div className="relative overflow-x-auto shadow-md sm:rounded-lg">
        <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
          <thead className="text-xs text-gray-700 bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-6 py-3">
                Date
              </th>
              <th scope="col" className="px-6 py-3">
                Principal(Interest)
              </th>
              <th scope="col" className="px-6 py-3">
                Payment Weekly
              </th>
              <th scope="col" className="px-6 py-3">
                You owe Us
              </th>
              <th scope="col" className="px-6 py-3">
                Remaining Balance
              </th>

              <th scope="col" className="px-6 py-3">
                Status
              </th>
              <th scope="col" className="px-6 py-3">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {amortizationData.map((rowData, index) => (
              <tr
                key={index}
                className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
              >
                <td className="px-6 py-4">{rowData.date}</td>
                <td className="px-6 py-4">
                  {rowData.principalPayment2?.toFixed(0).toLocaleString()} (
                  {rowData.weeklyAmort.toFixed(0)})
                </td>
                <td className="px-6 py-4">
                  {rowData.amount.toFixed(0).toLocaleString()}
                </td>
                <td className="px-6 py-4">
                  {rowData.overUnderPayment.toFixed(0).toLocaleString()}
                </td>
                <td className="px-6 py-4">
                  {rowData.balance.toFixed(0).toLocaleString()}
                </td>

                <td className="px-6 py-4">
                  <div className="flex items-center">
                    <div
                      className={`h-2.5 w-2.5 rounded-full ${
                        rowData.status === "Unpaid"
                          ? "bg-red-500"
                          : "bg-green-500"
                      } me-2`}
                    ></div>{" "}
                    {rowData.status}
                  </div>
                </td>
                <td className="px-6 py-4">
                  <div
                    type="button"
                    data-modal-target="editUserModal"
                    data-modal-show="editUserModal"
                    className={`font-medium  ${
                      rowData.status === "Unpaid"
                        ? "text-blue-600"
                        : "text-gray-600"
                    }  hover:underline`}
                  >
                    <Link to={`/payment/${id}/${_id}`}>mark paid</Link>{" "}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AmortizationSchedule;
