import React, { useState } from "react";
import { useEffect } from "react";

import Sidebar from "../../../sidebar/Sidebar";
import BotWidget from "./bottom/BotWidget";
import TopWidget from "./top/TopWidget";
import { Link, useNavigate } from "react-router-dom";
import { Logout, PermIdentity } from "@mui/icons-material";
import { server } from "../../../../server";
import { toast } from "react-toastify";
import axios from "axios";

export default function Home({ setAuth }) {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex h-[900px]">
      <Sidebar />
      <div className="w-full px-8 py-8 pb-8 mb-4 bg-white border rounded shadow-md h-[900px] ">
        {/* HOME ITEMS */}
        <div className="px-4 py-5 sm:px-6 rounded shadow-md bg-red-500 flex justify-between items-center">
          <div>
            <h3 className="text-xl font-medium leading-6 text-white">
              Welcome, {loading ? "Admin" : <>{user?.firstname}</>}
            </h3>
            <span className="text-md font-medium leading-6 text-white">
              Logged in: {new Date().toLocaleTimeString()}
            </span>
          </div>

          <div>
            {/* LOGOUT BUTTON */}
            <div className="text-white float-right ">
              {/* ADMIN PAGE */}
              <button className="hover:-translate-y-0.5">
                <Link to="/admin">
                  <PermIdentity />
                </Link>
              </button>

              {/* LOGOUT */}
              <button className="ml-2 hover:-translate-y-0.5">
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>
              </button>
            </div>
            <span className="mr-10 text-lg font-medium leading-6 text-white">
              {new Date().toLocaleString("en-US", {
                day: "numeric",
                month: "long",
                year: "numeric",
              }) + ""}
            </span>
          </div>
        </div>

        <TopWidget />
        <BotWidget />
      </div>
    </div>
  );
}
