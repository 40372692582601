import React, { useState } from "react";
import {
  ArrowBackIosNew,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { server } from "../../server";
import "react-toastify/dist/ReactToastify.css";

const Login = ({ setAuth }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${server}/user/login`, formData);

      if (response.data.message === "Login successfull") {
        if (response.data?.isAdmin === true) {
          localStorage.setItem("token", response.data.token);
          navigate(`/home`);
          setAuth(true);
        } else if (response.data?.isSeller === true) {
          localStorage.setItem("token", response.data.token);
          navigate(`/notifications`);
          setAuth(true);
        } else {
          navigate("/");
        }
        toast.success("Login successful!");
        setLoading(false);
      } else {
        toast.error("Login failed. Please check your credentials.");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);

      console.log(error);
      toast.error("Login failed. Please try again later.");
    }
    setLoading(false);
  };

  return (
    <div className="flex flex-col h-auto w-[620px] border rounded-md shadow-md mx-auto my-52 justify-center flex-wrap border-t-4 border-t-red-500">
      <div className="">
        <div className="flex justify-between items-center px-8 pt-6 pb-2">
          {/* GREETINGS */}
          <div>
            <h1 className="text-xl font-semibold">Welcome back</h1>
            <small className="text-gray-400">
              Welcome back! Please enter your details
            </small>
          </div>

          {/* BACK ARROW */}
          <div className="ml-8">
            <Link to="/">
              <ArrowBackIosNew />
            </Link>
          </div>
        </div>

        <form className="bg-white px-8 pt-6 pb-8" onSubmit={handleSubmit}>
          <div className="mb-4">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="email"
            >
              Email
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
              id="email"
              type="text"
              placeholder="Email"
              name="email"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4 relative">
            {" "}
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline"
              id="password"
              type={showPassword ? "text" : "password"}
              placeholder="******************"
              name="password"
              onChange={handleChange}
            />
            <div
              className="absolute inset-y-0 right-0 mt-3 flex items-center px-3 cursor-pointer"
              onClick={handleTogglePasswordVisibility}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </div>
          </div>
          <div className="mb-3">
            <span className="text-xs text-gray-400 font-semibold">
              Forgot Password?
            </span>
            <Link
              to="/forgot-password"
              className="text-xs font-semibold text-red-700 ml-2"
            >
              Reset Password
            </Link>
          </div>
          <div className="flex flex-col items-center justify-between gap-5">
            <button
              className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full"
              type="submit"
            >
              {loading ? "Signing in..." : "Sign In"}
            </button>
            <div className="text-center">
              <span className="text-xs text-gray-400 font-semibold">
                Don't have an account?
              </span>
              <Link
                to="/register"
                className="text-xs font-semibold text-red-700 ml-2"
              >
                Register
              </Link>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
