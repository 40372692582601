import React from "react";

const Gloader = () => {
  return (
    <div>
      <div class="flex justify-center items-center h-screen">
        <div class="rounded-full h-20 w-20 bg-red-600 animate-ping"></div>
      </div>
    </div>
  );
};

export default Gloader;
