import { Logout } from "@mui/icons-material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Sidebar from "../../sidebar/Sidebar";
import axios from "axios";
import { server } from "../../../server";

const AddSeller = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  const [formData, setFormData] = useState({
    firstname: "",
    secondname: "",
    phone: "",
    email: "",
    password: "",
    isSeller: true,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(`${server}/user/register`, formData);
      toast.success("Registration successful!");
      navigate("/admin");
      setLoading(false);
    } catch (error) {
      console.error("Registration failed", error.status);
      if (
        error?.response?.data?.error ===
        "Email already in use. Please use a different email."
      ) {
        toast.error("Email already in use. Please use a different email.");
      } else {
        toast.error(`${error?.response?.data?.error?.message}`);
      }
      setLoading(false);
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  const handleTogglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="flex h-[900px] ">
      <Sidebar />

      <div className="w-full h-[900px] border bg-white shadow-md rounded">
        <div className="w-full px-8 pt-6 pb-8 mb-4 bg-white  rounded ">
          {/* HEADER */}
          <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
            {/* TITLE */}
            <div>
              <h3 className="text-lg font-medium leading-6 text-white">
                Add New Marketer
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-white">
                Register all the required fields.
              </p>
            </div>

            {/* BUTTON */}

            <div className="text-white">
              <div className="text-white">
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>
              </div>
            </div>
          </div>

          <form
            onSubmit={handleSubmit}
            className="mt-5 p-8 rounded border shadow-md border-t-4 border-t-red-500 "
          >
            {/* FIRST NAME */}
            <label htmlFor="firstname">First Name: </label>
            <input
              type="text"
              className="block border border-grey-500 w-full p-3 rounded mb-4"
              name="firstname"
              onChange={handleChange}
              placeholder="First Name"
              required
            />
            {/* LAST NAME */}
            <label htmlFor="lastname">Last Name: </label>
            <input
              type="text"
              className="block border border-grey-500 w-full p-3 rounded mb-4"
              name="secondname"
              onChange={handleChange}
              placeholder="Last Name"
              required
            />
            {/* CONTACT NUMBER */}
            <label htmlFor="contactNumber">Contact Number: </label>
            <input
              type="number"
              className="block border border-grey-500t w-full p-3 rounded mb-4"
              name="phone"
              onChange={handleChange}
              placeholder="Contact Number"
              required
            />
            {/* EMAIL ADDRESS */}
            <label htmlFor="email">Email Address: </label>
            <input
              type="email"
              className="block border border-grey-500t w-full p-3 rounded mb-4"
              name="email"
              onChange={handleChange}
              placeholder="Email"
              required
            />
            {/* PASSWORD */}
            <div className="relative">
              <label htmlFor="password">Password: </label>
              <input
                type={showPassword ? "text" : "password"}
                className="block border border-grey-500t w-full p-3 rounded mb-4"
                name="password"
                onChange={handleChange}
                placeholder="**********"
                required
              />
              <div
                className="absolute inset-y-0 right-0 mt-6 flex items-center px-3 cursor-pointer"
                onClick={handleTogglePasswordVisibility}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </div>
            </div>
            {/* BUTTONS */}
            <button
              type="submit"
              className=" bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/6"
            >
              {loading ? "Saving..." : "Save"}
            </button>
            <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-1/6 ml-10">
              <Link to="/admin">Cancel</Link>
            </button>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddSeller;
