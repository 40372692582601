import { ReceiptLong } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import { server } from "../../../../../server";
import { toast } from "react-toastify";

export default function PaymentsWidget() {
  const [payments, setPayments] = useState([]);
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(false);

  const grossPaymentsGross = payments
    .map((payment) => payment.amount)
    .reduce((total, amount) => total + amount, 0);

  const grossPaymentsBalance = loans
    .map((loans) => loans.balance)
    .reduce((total, amount) => total + amount, 0);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/payments/get-all-payments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setPayments(data);
        const response2 = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data2 = await response2.json();
        setLoans(data2);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchPayments();
  }, []);

  return (
    <div className="w-full">
      {/* Payments */}
      <div
        className="w-full  mt-5 p-8 rounded-xl cursor-pointer border border-t-4 border-t-red-500 hover:bg-red-500
        hover:text-white hover:text-base transition duration-150
        ease-in-out shadow-md"
      >
        <span className="text-xl ">Payments</span>
        <div className="my-1">
          <span className="text-xl">
            <div className="flex">
              <ReceiptLong className="mr-3" />
              <div>Gross: Shs.</div>
              {loading ? (
                <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
              ) : (
                <> {grossPaymentsGross.toLocaleString()}</>
              )}
            </div>
          </span>
          <span className="text-xl">
            <div className="flex">
              <ReceiptLong className="mr-3" />
              <div>Balance: Shs.</div>
              {loading ? (
                <div class="h-2.5 mt-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-6"></div>
              ) : (
                <> {grossPaymentsBalance.toLocaleString()}</>
              )}
            </div>
          </span>
        </div>
        <span className="text-base text-gray-500">
          Total Payments Collected
        </span>
      </div>
      {/*  */}
    </div>
  );
}
