import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import { server } from "../../../../server";

export default function Message({ email, names }) {
  const [subject, setSubject] = useState("Loan Approval");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const token = localStorage.getItem("token");

      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }
      const response = await axios.post(
        `${server}/borrower/send-email`,
        {
          email,
          names,
          subject,
          message,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        toast.success(response.data.message);
        setMessage("");
        setSubject("Loan Approval");
      } else {
        toast.error(response.data.message);
      }
      setLoading(false);
    } catch (error) {
      console.error("Error sending message:", error);
      if (error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error("Failed to send message. Please try again.");
      }
      setLoading(false);
    }
  };

  return (
    <div className="flex">
      <div className="w-full h-[650px] px-4 mt-5 border rounded shadow-md border-t-4 border-t-red-500 ">
        <div class="w-full px-8  bg-white ">
          <div class=" py-2.5 ">
            <form class="space-y-8" onSubmit={handleSubmit}>
              {/* NAME EMAIL */}
              <div className="flex w-full gap-5">
                {/* FIRSTNAME */}
                <div className="w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="fullname"
                  >
                    Name:
                  </label>
                  <input
                    type="text"
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                    name="fullname"
                    value={names}
                    placeholder="Choose a borrower"
                  />
                </div>

                {/* EMAIL */}
                <div className="w-full">
                  <label
                    className="block text-gray-700 text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Email:
                  </label>
                  <input
                    type="email"
                    className="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                    name="email"
                    value={email}
                  />
                </div>
              </div>

              {/* SUBJECT */}
              <div>
                <label
                  for="subject"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Subject
                </label>
                <select
                  class="block p-3 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 shadow-sm focus:ring-primary-500 focus:border-primary-500"
                  name="subject"
                  id="subject"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                >
                  <option value="Loan Approval">Loan Approval</option>
                  <option value="Loan Denied">Loan Denied</option>
                  <option value="Loan Disbursed">Loan Disbursed</option>
                </select>
              </div>

              {/* MESSAGE */}
              <div class="sm:col-span-2">
                <label
                  for="message"
                  class="block mb-2 text-sm font-medium text-gray-900"
                >
                  Your message
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows="12"
                  class="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg shadow-sm border border-gray-300 focus:ring-primary-500 focus:border-primary-500"
                  placeholder="Write your message..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                ></textarea>
              </div>
              <button
                onClick={() => handleSubmit}
                type="submit"
                class="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline "
              >
                {loading ? "Sending..." : "Send message"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
