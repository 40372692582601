import { AddBox, PermIdentity, VisibilityOutlined } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import React, { useState } from "react";
import { useEffect } from "react";

import { Edit } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { server } from "../../../../../server";
import { toast } from "react-toastify";
import Loader from "../../../loader/Loader";

export default function DatesWidget() {
  const [loans, setLoans] = useState([]);
  const [borrowers, setBorrowers] = useState({});
  const [loading, setLoading] = useState([]);
  const [loading2, setLoading2] = useState([]);

  useEffect(() => {
    const fetchLoans = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/loans/get-all-loans`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setLoans(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    const fetchBorrowers = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const borrowerMap = {};
        data.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setBorrowers(borrowerMap);
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrowers:", error);
      }
    };

    fetchBorrowers();
    fetchLoans();
  }, []);
  const sortedLoans = [...loans]
    .filter((loan) => loan.status !== "Fully Paid")
    .sort((a, b) => {
      const dateA = new Date(a.maturity_date);
      const dateB = new Date(b.maturity_date);
      return dateA - dateB;
    });

  return (
    <div className="w-full h-[450px]  ">
      {/* CLIENTS */}
      <div className="mt-5 p-8 rounded-xl border border-t-4 border-t-red-500 cursor-pointer shadow-md">
        <h3 className="text-xl mb-5 border-b-2">Maturity Date</h3>
        <div className="flex justify-between items-center">
          <div className="w-full h-[350px]  overflow-auto hover:overflow-scroll">
            {loading ? (
              <Loader />
            ) : (
              <table className="table-fixed text-center w-full  ">
                <thead className="">
                  <tr className="">
                    <th className="w-1/1 px-1 py-2 ">Customer</th>
                    <th className="w-1/1 px-1 py-2 ">M. Date</th>
                    <th className="w-1/1 px-1 py-2 ">Balance</th>
                    <th className="w-1/1 px-1 py-2 ">status</th>
                    <th className="w-1/6 px-1 py-2 ">Email</th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {sortedLoans.length <= 0 ? (
                    <tr className="border px-4 py-2 bg-red-50">
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="px-4 py-2 bg-red-50">No Loan Data</td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  ) : (
                    sortedLoans.map((loan, index) => {
                      return (
                        <tr key={index} className>
                          <td className="border px-4 py-2  bg-gray-50">
                            {loading ? (
                              <div className="">
                                <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                              </div>
                            ) : (
                              <>
                                {borrowers[loan.borrower]?.firstname +
                                  " " +
                                  borrowers[loan.borrower]?.lastname}
                              </>
                            )}
                          </td>
                          <td className="border px-4 py-2 ">
                            {new Date(loan.maturity_date).toDateString()}
                          </td>
                          <td className="border px-4 py-2  bg-gray-50">
                            {loan.balance.toLocaleString()}
                          </td>
                          <td className="border text-sm  bg-gray-50">
                            {loan.balance === 0 ? (
                              <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                Fully Paid{" "}
                              </span>
                            ) : loan.status === "Approved" ||
                              loan.status === "Fully Paid" ||
                              loan.status === "Disbursed" ? (
                              <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                                {loan.status}
                              </span>
                            ) : loan.status === "Declined" ? (
                              <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                                {loan.status}
                              </span>
                            ) : (
                              <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                                {loan.status}
                              </span>
                            )}
                          </td>
                          <td className="border px-4 py-2">
                            <Link to={`/Borrower/${loan.borrower}`}>
                              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full ">
                                <VisibilityIcon className="text-sm" />
                              </button>
                            </Link>
                          </td>
                        </tr>
                      );
                    })
                  )}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
