import { DeleteForever, Edit, Update } from "@mui/icons-material";
import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import PaymentsInfo from "../payments/ListPayments";
import { server } from "../../../../server";
import { toast } from "react-toastify";

const OneLoan = () => {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchLoanById = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(
          `${server}/loans/get-specific-loan/${id}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = await response.json();
        setLoans(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error fetching borrower:", error);
      }
    };

    fetchLoanById();
  }, [id]);

  return (
    <div>
      {/* Loans Information */}
      <div className="mt-5 px-4 py-2 h-[180px] rounded border shadow-md border-t-4 border-t-red-500 ">
        {/* Active Loans */}
        <div>
          <div className="flex items-center justify-between border-y-2 ">
            <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
              Loan Info
            </h3>
          </div>
          {loading ? (
            <div
              role="status"
              class="p-4 space-y-4 m-3 rounded border border-gray-200 divide-y divide-gray-200 shadow animate-pulse dark:divide-gray-700 md:p-6 dark:border-gray-700"
            >
              <div class="flex justify-between items-center">
                <div>
                  <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-600 w-24 mb-2.5"></div>
                  <div class="w-32 h-2 bg-gray-200 rounded-full dark:bg-gray-700"></div>
                </div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
              </div>
              <span class="sr-only">Loading...</span>
            </div>
          ) : (
            <table className="table-fixed text-center ">
              <thead>
                <tr>
                  <th className="w-1/6 px-1 py-2 text-gray-600">Loan Type</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">
                    Outstanding Balance
                  </th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Gross Loan</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">
                    Amortization
                  </th>
                  <th className="w-1/1 px-1 py-2 text-gray-600">Grand Loan</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Terms</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">
                    Date Released
                  </th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Status</th>
                </tr>
              </thead>
              <tbody>
                {loans.length <= 0 ? (
                  <tr className="border px-4 py-2 bg-red-50">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="px-4 py-2 bg-red-50">No Loan Data</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  <tr>
                    <td className="border px-4 py-2">{loans.type}</td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {loans.balance.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 ">
                      {" "}
                      {loans.gross_loan.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {loans.amort.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {loans.grand_amount?.toLocaleString()}
                    </td>
                    <td className="border px-4 py-2 ">{loans.terms} week/s</td>
                    <td className="border px-4 py-2 bg-gray-50">
                      {new Date(loans.date_released).toDateString()}
                    </td>
                    <td className="border text-sm ">
                      {loans.balance === 0 ? (
                        <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                          Fully Paid{" "}
                        </span>
                      ) : loans.status === "Approved" ||
                        loans.status === "Fully Paid" ? (
                        <span className=" bg-green-500 text-white px-2 py-1 rounded-md">
                          {loans.status}
                        </span>
                      ) : loans.status === "Declined" ? (
                        <span className=" bg-red-400 text-white px-4 py-1 rounded-md">
                          {loans.status}
                        </span>
                      ) : loans.status === "Pending" ? (
                        <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                          {loans.status}
                        </span>
                      ) : (
                        <span className=" bg-orange-300 text-white px-4 py-1 rounded-md">
                          {loans.status}
                        </span>
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default OneLoan;
