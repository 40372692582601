import React, { useState } from "react";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { DeleteForever, Logout } from "@mui/icons-material";
import { toast } from "react-toastify";

import Sidebar from "../../../sidebar/Sidebar";
import { server } from "../../../../server";
import axios from "axios";
import Loader from "../../loader/Loader";

const Payments = ({ setAuth }) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [borrowers, setBorrowers] = useState({});

  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);

  useEffect(() => {
    const fetchPayments = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/payments/get-all-payments`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const sortedPayments = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        setLoading(false);
        setPayments(sortedPayments);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching borrowers:", error);
      }
    };
    const fetchBorrowers = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrowers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        const sortedPayments = data.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        });
        const borrowerMap = {};
        sortedPayments.forEach((borrower) => {
          borrowerMap[borrower._id] = borrower;
        });
        setBorrowers(borrowerMap);
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrowers:", error);
      }
    };
    fetchBorrowers();
    fetchPayments();
  }, []);

  // Delete payment Function
  const deletePayment = async () => {
    toast.info(
      "Delicate Action!! Please go specific Borrower to Delete this payment!!"
    );
  };

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex  h-[900px]">
      <Sidebar />
      {/* PAYMENTS */}
      <div className="w-full h-[900px] mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        {/* HEADER */}
        <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
          <div>
            <h3 className="text-lg font-medium leading-6 text-white">
              Payments Report
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-white">
              Summary of Collections and information.
            </p>
          </div>
          {/* BUTTON */}

          <div className="text-white">
            <button className="">
              <button className="" onClick={handleLogout}>
                <Logout />
              </button>
            </button>
          </div>
        </div>

        {/* TITLE */}
        <div className="flex items-center justify-between border-y-2 mt-5">
          <h3 className="text-lg font-medium leading-6 text-gray my-2  px-1 py-2 ">
            Payment Transactions
          </h3>
          <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 mb-2 rounded focus:outline-none focus:shadow-outline w-auto mt-2">
            <Link to="/borrowers">Add Payment</Link>
          </button>
        </div>

        <div className="w-full h-[650px] px-4 overflow-auto hover:overflow-scroll mt-5 border rounded shadow-md border-t-4 border-t-red-500">
          {loading ? (
            <Loader />
          ) : (
            <table className="table-fixed text-center ">
              <thead>
                <tr>
                  <th className="w-1/1 px-1 py-2 text-gray-600">ID</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">Client Name</th>
                  <th className="w-1/1 px-1 py-2 text-gray-600">Voucher ID</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">Amount</th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">
                    Collection Date
                  </th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">
                    Collected By:
                  </th>
                  <th className="w-1/6 px-1 py-2 text-gray-600">New Balance</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Method</th>
                  <th className="w-1/6 px-4 py-2 text-gray-600">Delete</th>
                </tr>
              </thead>
              <tbody>
                {payments.length <= 0 ? (
                  <tr className="border px-4 py-2 bg-red-50">
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td className="px-4 py-2 bg-red-50">No Payment</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ) : (
                  payments.map((payment, index) => {
                    return (
                      <tr key={index}>
                        <td className="border px-4 py-2 bg-gray-50">
                          {payment.id}
                        </td>
                        <td className="border px-4 py-2">
                          {loading2 ? (
                            <div className="">
                              <div class="h-2.5 mb-3 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                              <div class="h-2.5 bg-gray-300 rounded-full dark:bg-gray-700 w-12"></div>
                            </div>
                          ) : (
                            <>
                              {borrowers[payment.client_id]?.firstname}{" "}
                              {borrowers[payment.client_id]?.lastname}
                            </>
                          )}
                        </td>
                        <td className="border px-4 py-2  bg-gray-50">
                          {" "}
                          {payment.loan_id.replace(/\D/g, "")}
                        </td>
                        <td className="border px-4 py-2 ">
                          {" "}
                          {payment.amount.toLocaleString()}
                        </td>
                        <td className="border px-4 py-2  bg-gray-50">
                          {new Date(payment.collection_date).toDateString()}
                        </td>
                        <td className="border px-4 py-2 ">
                          {payment.collected_by}
                        </td>
                        <td className="border px-4 py-2  bg-gray-50">
                          {payment.new_balance.toLocaleString()}
                        </td>
                        <td className="border px-4 py-2 ">
                          {payment.method === "ATM" ? (
                            <span className=" bg-green-500 text-white px-4 py-1 rounded-md">
                              {payment.method}
                            </span>
                          ) : payment.method === "OTC" ? (
                            <span className=" bg-yellow-300 text-white px-4 py-1 rounded-md">
                              {payment.method}
                            </span>
                          ) : payment.method === "ONLINE BANK" ? (
                            <span className=" bg-orange-300 text-white px-4 py-1 rounded-md">
                              {payment.method}
                            </span>
                          ) : (
                            <span className=" bg-blue-500 text-white px-4 py-1 rounded-md">
                              {payment.method}
                            </span>
                          )}
                        </td>
                        <td className="border px-4 py-2">
                          <button
                            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline w-full text-sm"
                            onClick={() => deletePayment()}
                          >
                            <DeleteForever className="text-lg" />
                          </button>
                        </td>
                      </tr>
                    );
                  })
                )}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </div>
  );
};

export default Payments;
