import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Logout } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import Sidebar from "../../../sidebar/Sidebar";
import { useParams } from "react-router-dom";
import { server } from "../../../../server";
import axios from "axios";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ControlPointDuplicateIcon from "@mui/icons-material/ControlPointDuplicate";
import Gloader from "../../loader/Gloader";

const AddGuarantors = ({ setAuth }) => {
  const [loading, setLoading] = useState(false);
  const [images, setImages] = useState([]);
  const [guarantor_images, setGuarantorImages] = useState([]);
  const [guarantor_images2, setGuarantorImages2] = useState([]);

  const [currentImages, setcurrentImages] = useState([]);
  const [currentGuarantorImages, setcurrentGuarantorImages] = useState([]);
  const [currentGuarantorImages2, setcurrentGuarantorImages2] = useState([]);

  const [select, setSelect] = useState(0);
  const [loading2, setLoading2] = useState(false);

  const [index, setIndex] = useState("");
  const [index2, setIndex2] = useState("");

  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    phone: "",
    idno: "",
    email: "",
    guarantor_name: "",
    guarantor_phone: "",
    guarantor_idno: "",
    guarantor_name2: "",
    guarantor_phone2: "",
    guarantor_idno2: "",
  });

  const [user, setUser] = useState();

  const email = user?.email;

  useEffect(() => {
    const fetchBorrowerByToken = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }

        const response = await fetch(`${server}/user/get-user`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();

        setUser(data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error("Error fetching user:", error);
      }
    };

    fetchBorrowerByToken();
  }, []);
  // console.log("form data", formData);

  useEffect(() => {
    const fetchBorrowerById = async () => {
      setLoading2(true);
      try {
        const token = localStorage.getItem("token");

        if (!token) {
          toast.error("Not authorised. Login!");
          return;
        }
        const response = await fetch(`${server}/borrower/get-borrower/${id}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();

        console.log("data", data);

        if (response.ok) {
          setFormData({
            firstname: data.firstname,
            lastname: data.lastname,
            phone: data.phone,
            idno: data.idno,
            email: data.email,
            guarantor_name: data.guarantor_name,
            guarantor_phone: data.guarantor_phone,
            guarantor_idno: data.guarantor_idno,
            guarantor_name2: data.guarantor_name2,
            guarantor_phone2: data.guarantor_phone2,
            guarantor_idno2: data.guarantor_idno2,
          });
          setcurrentImages(data.documents);
          setcurrentGuarantorImages(data.documents_guarantor);
          setcurrentGuarantorImages2(data.documents_guarantor2);
        } else {
          toast.error(data.error || "Failed to fetch borrower");
        }
        setLoading2(false);
      } catch (error) {
        setLoading2(false);
        console.error("Error fetching borrower:", error);
        toast.error("Internal Server Error");
      }
    };

    fetchBorrowerById();
  }, [id]);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  // const handleImageChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setImages((old) => [...old, reader.result]);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };
  // const handleGuarantorImageChange = (e) => {
  //   const files = Array.from(e.target.files);

  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setGuarantorImages((old) => [...old, reader.result]);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };
  // const handleGuarantorImageChange22 = (e) => {
  //   const files = Array.from(e.target.files);

  //   files.forEach((file) => {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       setGuarantorImages2((old) => [...old, reader.result]);
  //     };
  //     reader.readAsDataURL(file);
  //   });
  // };

  const MAX_FILE_SIZE = 200 * 1024; // 200KB

  const handleImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setImages((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleGuarantorImageChange = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setGuarantorImages((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };
  const handleGuarantorImageChange22 = (e) => {
    const files = Array.from(e.target.files);

    files.forEach((file) => {
      const reader = new FileReader();
      reader.onload = () => {
        const image = new Image();
        image.src = reader.result;
        image.onload = () => {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          const MAX_WIDTH = 800; // Max width for the image
          const MAX_HEIGHT = 600; // Max height for the image

          let width = image.width;
          let height = image.height;

          // Resize the image if it exceeds the max width or height
          if (width > MAX_WIDTH || height > MAX_HEIGHT) {
            const aspectRatio = width / height;
            if (width > height) {
              width = MAX_WIDTH;
              height = width / aspectRatio;
            } else {
              height = MAX_HEIGHT;
              width = height * aspectRatio;
            }
          }

          canvas.width = width;
          canvas.height = height;

          ctx.drawImage(image, 0, 0, width, height);

          canvas.toBlob(
            (blob) => {
              if (blob.size <= MAX_FILE_SIZE) {
                setGuarantorImages2((old) => [...old, canvas.toDataURL()]);
              } else {
                toast.error("Image size exceeds 200KB");
              }
            },
            "image/jpeg",
            0.6
          ); // 0.6 is the image quality (adjust as needed)
        };
      };
      reader.readAsDataURL(file);
    });
  };

  const handleImageChange2 = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setcurrentImages((prevImages) => [...prevImages, ...files]);
  };

  const handleGuarantorImageChange2 = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setcurrentGuarantorImages((prevImages) => [...prevImages, ...files]);
  };

  const handleGuarantor2ImageChange2 = (e) => {
    e.preventDefault();

    let files = Array.from(e.target.files);
    setcurrentGuarantorImages((prevImages) => [...prevImages, ...files]);
  };

  const deleteImage = async (index, image) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        setLoading(false);
        return;
      }
      const loadingToastId = toast.info("Deleting Image...", {
        autoClose: false,
      });
      await axios.delete(`${server}/borrower/delete-image/${id}`, {
        data: { image },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss(loadingToastId);
      const updatedImages = [...currentImages];
      updatedImages.splice(index, 1); // Remove the image at the specified index
      setcurrentImages(updatedImages);
      toast.success("Image deleted!");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  const deleteGuarantorImage = async (index, image) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        setLoading(false);
        return;
      }
      const loadingToastId = toast.info("Deleting Image...", {
        autoClose: false,
      });
      await axios.delete(`${server}/borrower/delete-guarantor-image/${id}`, {
        data: { image },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss(loadingToastId);
      const updatedImages = [...currentGuarantorImages];
      updatedImages.splice(index, 1); // Remove the image at the specified index
      setcurrentGuarantorImages(updatedImages);
      toast.success("Image deleted!");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };
  const deleteGuarantorImage2 = async (index, image) => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        setLoading(false);
        return;
      }
      const loadingToastId = toast.info("Deleting Image...", {
        autoClose: false,
      });
      await axios.delete(`${server}/borrower/delete-guarantor-image2/${id}`, {
        data: { image },
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      toast.dismiss(loadingToastId);
      const updatedImages = [...currentGuarantorImages2];
      updatedImages.splice(index, 1); // Remove the image at the specified index
      setcurrentGuarantorImages2(updatedImages);
      toast.success("Image deleted!");
    } catch (error) {
      console.log(error);
      toast.error(error.response.data.error);
    }
  };

  const setOperations = async (index, image) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (isConfirmed) {
      setIndex(index);
      deleteImage(index, image);
    }
  };
  const setOperations2 = async (index, image) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (isConfirmed) {
      setIndex(index);
      deleteGuarantorImage(index, image);
    }
  };

  const setOperations3 = async (index, image) => {
    const isConfirmed = window.confirm(
      "Are you sure you want to delete this image?"
    );

    if (isConfirmed) {
      setIndex(index);
      deleteGuarantorImage2(index, image);
    }
  };

  const deleteImage2 = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  const deleteeGuarantorImage2 = (index) => {
    const updatedImages = [...guarantor_images];
    updatedImages.splice(index, 1);
    setGuarantorImages(updatedImages);
  };
  const deleteeGuarantor2Image2 = (index) => {
    const updatedImages = [...guarantor_images2];
    updatedImages.splice(index, 1);
    setGuarantorImages2(updatedImages);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        toast.error("Not authorised. Login!");
        return;
      }

      const firstname = formData.firstname;
      const lastname = formData.lastname;
      const idno = formData.idno;
      const phone = formData.phone;
      const email = formData.email;
      const guarantor_name = formData.guarantor_name;
      const guarantor_phone = formData.guarantor_phone;
      const guarantor_idno = formData.guarantor_idno;
      const guarantor_name2 = formData.guarantor_name2;
      const guarantor_phone2 = formData.guarantor_phone2;
      const guarantor_idno2 = formData.guarantor_idno2;

      const newForm = new FormData();

      images.forEach((image, index) => {
        newForm.append("images", image);
      });
      guarantor_images.forEach((image, index) => {
        newForm.append("guarantor_images", image);
      });
      guarantor_images2.forEach((image, index) => {
        newForm.append("guarantor_images2", image);
      });

      newForm.append("firstname", firstname);
      newForm.append("lastname", lastname);
      newForm.append("idno", idno);
      newForm.append("phone", phone);
      newForm.append("email", email);
      newForm.append("guarantor_name", guarantor_name);
      newForm.append("guarantor_phone", guarantor_phone);
      newForm.append("guarantor_idno", guarantor_idno);
      newForm.append("guarantor_name2", guarantor_name2);
      newForm.append("guarantor_phone2", guarantor_phone2);
      newForm.append("guarantor_idno2", guarantor_idno2);

      const response = await axios.put(
        `${server}/borrower/update-borrower-marketer/${id}`,
        newForm,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      toast.success("Borrower updated successfully");
      navigate(`/Borrower/${id}`);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
      toast.error("Internal Server Error");
    }
    setLoading(false);
  };

  const handleLogout = async () => {
    try {
      await axios.post(`${server}/user/logout`, { email });
      localStorage.removeItem("token");
      toast.success("Logout Success!");
      navigate("/login");
      setAuth(false);
    } catch (error) {
      console.log(error);
      toast.error("Error Occured!");
    }
  };

  return (
    <div className="flex h-fit">
      <Sidebar />

      <div className="w-full h-fit mx-auto px-8 py-8 mb-4 border bg-white shadow-md rounded">
        <div className="w-full px-8 pt-6 pb-8 mb-4 bg-white  rounded">
          {/* HEADER */}
          <div className="flex items-center justify-between px-4 py-5 sm:px-6 bg-red-500 rounded shadow-md ">
            {/* TITLE */}
            <div>
              <h3 className="text-lg font-medium leading-6 text-white">
                Add Guarantors for {formData?.firstname} {formData?.lastname} #
                {id.replace(/\D/g, "")}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-white">
                Update all the required fields.
              </p>
            </div>

            {/* BUTTON */}

            <div className="text-white">
              <button
                className=""
                onClick={(e) => {
                  setAuth(false);
                }}
              >
                <button className="" onClick={handleLogout}>
                  <Logout />
                </button>
              </button>
            </div>
          </div>
          {/* FORM */}
          {loading2 ? (
            <Gloader />
          ) : (
            <form
              className="mt-5 p-8 h-fit rounded border shadow-md border-t-4 border-t-red-500 "
              onSubmit={handleFormSubmit}
            >
              <div className="grid gap-6 mb-6 md:grid-cols-2">
                <div>
                  <p className="text-lg	text-orange-700	">
                    Guarantor One Details{" "}
                  </p>{" "}
                  <div class="">
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_name"
                      placeholder="Guarantor Names"
                      value={formData.guarantor_name}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_phone"
                      placeholder="Guarantor Contact Number"
                      value={formData.guarantor_phone}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_idno"
                      placeholder="Guarantor Id Number"
                      value={formData.guarantor_idno}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="pb-2">Current Images</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        className="hidden"
                        multiple
                        onChange={handleImageChange2}
                      />
                      <div className="w-full flex items-center flex-wrap">
                        <label htmlFor="upload"></label>
                        {currentGuarantorImages &&
                          currentGuarantorImages.map((image, index) => (
                            <>
                              <div
                                className="border rounded-md mb-2 cursor-pointer"
                                key={index}
                              >
                                <img
                                  src={`${image.url}`}
                                  alt=""
                                  className="h-[120px] w-[120px] object-cover m-2"
                                />
                                <div
                                  className="text-red-500 cursor-pointer"
                                  onClick={() => setOperations2(index, image)}
                                >
                                  <DeleteOutlineIcon size={20} />
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                      <br />
                    </div>
                    <div className="ml-7">
                      <label className="pb-2">
                        Upload Images <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="file"
                        name="documents"
                        id="guarantor_upload"
                        className="hidden"
                        multiple
                        onChange={handleGuarantorImageChange}
                      />
                      <div className="w-full flex items-center flex-wrap">
                        <label htmlFor="guarantor_upload" className="mr-5">
                          <ControlPointDuplicateIcon
                            size={30}
                            className="mt-3"
                            color="#555"
                          />
                        </label>
                        {guarantor_images &&
                          guarantor_images.map((image, index) => (
                            <div
                              className="border rounded-md mr-2 cursor-pointer"
                              key={index}
                            >
                              <img
                                src={image}
                                alt=""
                                className="h-[120px] w-[120px] object-cover m-2"
                              />
                              <div
                                className="text-red-500 cursor-pointer"
                                onClick={() =>
                                  deleteeGuarantorImage2(index, image)
                                }
                              >
                                <DeleteOutlineIcon size={20} />
                              </div>
                            </div>
                          ))}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
                <div>
                  <p className="text-lg	text-orange-700	">
                    Guarantor Two Details{" "}
                  </p>{" "}
                  <div class="">
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_name2"
                      placeholder="Guarantor Names"
                      value={formData.guarantor_name2}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_phone2"
                      placeholder="Guarantor Contact Number"
                      value={formData.guarantor_phone2}
                      onChange={handleInputChange}
                    />
                    <input
                      type="text"
                      className="block border border-grey-500t w-full p-3 rounded mb-4"
                      name="guarantor_idno2"
                      placeholder="Guarantor Id Number"
                      value={formData.guarantor_idno2}
                      onChange={handleInputChange}
                    />
                  </div>
                  <div class="grid gap-6 mb-6 md:grid-cols-2">
                    <div>
                      <label className="pb-2">Current Images</label>
                      <input
                        type="text"
                        name=""
                        id=""
                        className="hidden"
                        multiple
                        onChange={handleGuarantor2ImageChange2}
                      />
                      <div className="w-full flex items-center flex-wrap">
                        <label htmlFor="upload"></label>
                        {currentGuarantorImages2 &&
                          currentGuarantorImages2.map((image, index) => (
                            <>
                              <div
                                className="border rounded-md mb-2 cursor-pointer"
                                key={index}
                              >
                                <img
                                  src={`${image.url}`}
                                  alt=""
                                  className="h-[120px] w-[120px] object-cover m-2"
                                />
                                <div
                                  className="text-red-500 cursor-pointer"
                                  onClick={() => setOperations3(index, image)}
                                >
                                  <DeleteOutlineIcon size={20} />
                                </div>
                              </div>
                            </>
                          ))}
                      </div>
                      <br />
                    </div>
                    <div className="ml-7">
                      <label className="pb-2">
                        Upload Images <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="file"
                        name="documents"
                        id="guarantor_upload2"
                        className="hidden"
                        multiple
                        onChange={handleGuarantorImageChange22}
                      />
                      <div className="w-full flex items-center flex-wrap">
                        <label htmlFor="guarantor_upload2" className="mr-5">
                          <ControlPointDuplicateIcon
                            size={30}
                            className="mt-3"
                            color="#555"
                          />
                        </label>
                        {guarantor_images2 &&
                          guarantor_images2.map((image, index) => (
                            <div
                              className="border rounded-md mr-2 cursor-pointer"
                              key={index}
                            >
                              <img
                                src={image}
                                alt=""
                                className="h-[120px] w-[120px] object-cover m-2"
                              />
                              <div
                                className="text-red-500 cursor-pointer"
                                onClick={() =>
                                  deleteeGuarantor2Image2(index, image)
                                }
                              >
                                <DeleteOutlineIcon size={20} />
                              </div>
                            </div>
                          ))}
                      </div>
                      <br />
                    </div>
                  </div>
                </div>
              </div>

              <button
                type="submit"
                className=" text-center py-3 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none my-1 w-1/5"
              >
                {loading ? "Updating..." : "Update"}
              </button>
              <button className=" ml-5 text-center py-3 rounded bg-red-500 text-white hover:bg-red-700 focus:outline-none my-1 w-1/5">
                <Link to={`/Borrower/${id}`}>Back</Link>
              </button>
            </form>
          )}
        </div>
      </div>
    </div>
  );
};

export default AddGuarantors;
